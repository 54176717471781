import apiConnector from 'api';

const createIntentAndGetSecret = async (stripeId) => {
  const route = '/stripe/create-intent-no-db';

  const response = await apiConnector.global.post(route, { stripeId });

  const {
    data: {
      setupIntent: {
        client_secret, // eslint-disable-line camelcase
      },
    },
  } = response;

  return client_secret;
};

export default createIntentAndGetSecret;
