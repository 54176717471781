import styled from 'styled-components';

export const Title = styled.h1`
  font-size: 70px;
  font-family: GTEestiProDisplay, Roboto, Arial, sans-serif;
  font-weight: bold;
  color: #fff;
  line-height: 1.25;
  margin-bottom: 20px;
`;

export const Subtitle = styled.p`
  font-size: 1.875rem;
  font-family: GTEestiProDisplay, Roboto, Arial, sans-serif;
  font-weight: medium;
  color: #fff;
  margin-bottom: 10px;
`;

export const FormWrapper = styled.div`
  width: 100%;
  max-width: 550px;
  margin: 0 auto;
  padding: 20px 25px 10px;
  border-radius: 10px; 
`;
