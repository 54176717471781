import React from 'react';
import PropTypes from 'prop-types';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const publishableKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;

const stripePromise = loadStripe(publishableKey);


const StripeProvider = ({ children }) => (
  <Elements stripe={stripePromise}>
    {children}
  </Elements>
);

StripeProvider.defaultProps = {
  children: null,
};

const {
  arrayOf,
  element,
  oneOfType,
} = PropTypes;

StripeProvider.propTypes = {
  children: oneOfType([element, arrayOf(element)]),
};

export default StripeProvider;
