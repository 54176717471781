import React from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';

import Typography from 'ui/Typography';
import Flex from 'ui/Flex';

const PricingAdvanced = (props) => {
  const {
    offer,
  } = props;

  return (
    <div>
      <Flex alignItems="flex-end" spacing={2}>
        {offer?.custom_months_cost.map((item, index) => {
          const month = moment().add(index, 'M').format('MMM');

          return (
            <div>
              <Typography style={{ marginBottom: 6 }}>
                {month}
              </Typography>
              <Typography variant="title2" tag="span" noMargin>
                $
                {item[1]}
              </Typography>
            </div>
          );
        })}
      </Flex>
      <Typography noMargin style={{ marginTop: 8 }}>
        then from
        {' '}
        {moment().add(offer?.custom_months_cost.length, 'M').format('MMMM')}
        {' '}
        the montly cost will be $
        {offer?.simple_monthly_price}
      </Typography>
    </div>
  );
};

PricingAdvanced.defaultProps = {

};

const {
  array,
  arrayOf,
  bool,
  element,
  func,
  instanceOf,
  number,
  object,
  objectOf,
  oneOf,
  oneOfType,
  shape,
  string,
} = PropTypes;

PricingAdvanced.propTypes = {

};

export default PricingAdvanced;
