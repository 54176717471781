import { connect } from 'react-redux';

import { setDetailsFormFields } from 'store/actions/detailsForm';
import { createAccount } from 'store/actions/account';

import SubscriptionChoiceSection from './SubscriptionChoiceSection';

const mapDispatchToProps = (dispatch) => ({
  setDetailsFormFields: (fields) => {
    dispatch(setDetailsFormFields(fields));
  },
  createAccount: () => dispatch(createAccount())
});

export default connect(null, mapDispatchToProps)(SubscriptionChoiceSection);
