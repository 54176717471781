// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles_stepFooterText__3tWmm {\n  font-size: 20px;\n  font-weight: 400;\n  text-align: center;\n  color: #35363e; }\n  .styles_stepFooterText__3tWmm a {\n    color: inherit;\n    text-decoration: underline; }\n  @media (max-width: 425px) {\n    .styles_stepFooterText__3tWmm {\n      font-size: 16px;\n      padding: 0 25px;\n      text-align: left; } }\n", ""]);
// Exports
exports.locals = {
	"stepFooterText": "styles_stepFooterText__3tWmm"
};
module.exports = exports;
