import styled from 'styled-components';

import get from 'lodash/get';

import defaultTheme from 'ui/defaultTheme';

const getThemeProperty = (themePropertyName, propsPropertyName, propsOverridePropertyName) => (props) => {
  if (props[propsOverridePropertyName]) return props[propsOverridePropertyName];

  const themeValue = get(props.theme, themePropertyName);

  if (themeValue) {
    return themeValue[props[propsPropertyName]] || themeValue.default;
  }

  const defaultThemeValue = get(defaultTheme, themePropertyName);

  return defaultThemeValue[props[propsPropertyName]] || defaultThemeValue.default;
};

export const Root = styled.span`
  font-family: ${getThemeProperty('typography.fontFamily', 'variant', 'overrideFontFamily')}; 
  font-size: ${getThemeProperty('typography.fontSize', 'variant', 'fontSize')}; 
  line-height: ${getThemeProperty('typography.lineHeight', 'variant', 'lineHeight')}; 
  font-weight: ${getThemeProperty('typography.fontWeight', 'variant', 'weight')}; 
  text-decoration: ${getThemeProperty('typography.textDecoration', 'variant', 'textDecoration')}; 
  font-style: ${getThemeProperty('typography.fontStyle', 'variant', 'fontStyle')}; 

  color: ${getThemeProperty('palette', 'color', 'overrideColor')}; 

  ${(props) => (props.align ? `text-align: ${props.align}` : '')};
  ${(props) => (props.noWrap ? 'white-space: nowrap' : '')};

  ${(props) => `margin-bottom: ${props.noMargin
    ? '0'
    : getThemeProperty('typography.marginBottom', 'variant')(props)}
  `};

  ${(props) => {
    if (props.ellipsis) {
      return `
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      `;
    }
    if (props.multilineEllipsis) {
      return `
        text-overflow: ellipsis;
        overflow: hidden;
      `;
    }
    return '';
  }}
`;

export default {
  Root,
};
