import { connect } from 'react-redux';

import ErrorOnSignup from './ErrorOnSignup';

const mapStateToProps = (state) => {
  const {
    detailsForm: {
      firstName: {
        value: firstName,
      },
    },
  } = state;

  return {
    firstName,
  };
};

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(ErrorOnSignup);
