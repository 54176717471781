import React, {
  useState,
  useEffect,
} from 'react';

import {
  Row,
  Col,
} from 'antd';

import logo from 'assets/logo-light.png';

import styles from './styles.module.scss';

import {
  CallOfficeTitle,
  PhoneLink,
  PhoneAvailabilityText,
} from './styledItems';

const Header = (props) => {
  const [phoneAvailable, setPhoneAvailable] = useState(false);

  useEffect(() => {
    const currentDate = new Date();
    const pstDateString = currentDate.toLocaleString('en-US', { timeZone: 'America/Los_Angeles' });
    const pstDate = new Date(pstDateString);

    const hours = pstDate.getHours();
    const mins = pstDate.getMinutes();
    const day = pstDate.getDay();

    const isPhoneAvailable = (hours > 7 || hours === 7 && mins >= 30)
      && (hours < 15 || hours === 15 && mins <= 30);

    setPhoneAvailable(isPhoneAvailable);
  }, []);

  return (
    <header className={styles.main}>
      <Row type="flex" align="middle" gutter={[8, 24]}>
        <Col xs={24} md={12}>
          <div className={styles.logoSection}>
            <a
              className={styles.sympleteLogoLink}
              href="https://symplete.com"
            >
              <img src={logo} height={50} alt="Symplete Realtor logo" />
            </a>
          </div>
        </Col>
        <Col xs={24} md={12}>
          <CallOfficeTitle>
            Call Sales:
            {' '}
            <PhoneLink href="tel:+18886641952">
              +1 (888) 664-1952
            </PhoneLink>
          </CallOfficeTitle>
          {
            phoneAvailable && (
              <PhoneAvailabilityText>
                We’re available!
              </PhoneAvailabilityText>
            )
          }
        </Col>
      </Row>
    </header>
  );
};

export default Header;
