import { connect } from 'react-redux';

import InstallationProgressSection from './InstallationProgressSection';

const mapStateToProps = (state) => {
  const {
    detailsForm: {
      clientEmail,
    },
    account: {
      creationProgress,
    },
  } = state;

  return {
    clientEmail: clientEmail.value,
    percent: creationProgress || 0,
  };
};

export default connect(mapStateToProps)(InstallationProgressSection);
