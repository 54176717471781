import { connect } from 'react-redux';

import CustomOffer from './CustomOffer';

const mapStateToProps = (state, ownProps) => {
  const {

  } = state;

  const token = ownProps?.match?.params?.token;

  return {
    token,
  };
};

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(CustomOffer);
