import React from 'react';

import {
  Row,
  Col,
} from 'antd';

import logo from 'assets/logo.svg';

import Typography from 'ui/Typography';

import Salesperson from './Salesperson';

import styles from './styles.module.scss';

const HeaderSimple = (props) => (
  <header className={styles.main}>
    <Row type="flex" align="middle">
      <Col span={12}>
        <div className={styles.logoSection}>
          <a
            className={styles.sympleteLogoLink}
            href="https://start-realtor.symplete.com"
          >
            <img src={logo} height={35} alt="Symplete Realtor logo" />
          </a>
        </div>
        {
          props.offerId && (
            <Typography
              style={{
                fontFamily: 'Poppins, Lato, Helvetica, Arial, sans-serif',
                fontSize: '18px',
                marginTop: '16px',
              }}
            >
              Offer ID: #
              { props.offerId }
            </Typography>
          )
        }
      </Col>
      {
        props.salesperson && (
          <Col span={12}>
            <Salesperson salesperson={props.salesperson} />
          </Col>
        )
      }
    </Row>
  </header>
);

export default HeaderSimple;
