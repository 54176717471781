import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import {
  setDetailsFormFields,
  handleDetailsFormSubmit,
  checkIfAccountExists,
  setSubmitted,
} from 'store/actions/detailsForm';

import DetailsForm from './DetailsForm';

const mapStateToProps = (state) => {
  const {
    detailsForm,
  } = state;

  sessionStorage.setItem('detailsForm', JSON.stringify(detailsForm));

  return {
    fields: { ...detailsForm },
    accountExists: detailsForm.accountExists,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  setFields: (fields) => dispatch(setDetailsFormFields(fields)), // TODO: change set fields method here
  onSubmit: () => {
    if (!ownProps.experimentWithCardForm && ownProps.emitExperimentWin) {
      ownProps.emitExperimentWin();
    }

    dispatch(handleDetailsFormSubmit(ownProps.experimentWithCardForm));
    dispatch(setSubmitted(true));
  },
  checkIfAccountExists: (email) => dispatch(checkIfAccountExists(email)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DetailsForm),
);
