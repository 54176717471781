import account from './account';
import stripe from './stripe';
import stalled from './stalled';
import specialOffers from './specialOffers';
import external from './external';

export default {
  account,
  stripe,
  stalled,
  specialOffers,
  external,
};
