// TODO - refactor
// Need to create separate reducer files for different entities

import connectReducers from 'store/connectReducers';

import {
  CHECKBOX_TC_ERROR,
  CUSTOMER_CHECKBOX_CHECK,
  CUSTOMER_SET_IS_CUSTOMER,
  CUSTOMER_SET_MIN_COUNT,
  FETCH_FREE_LICENSES_FAILURE,
  FETCH_FREE_LICENSES_SUCCESS,
  FETCH_LICENSES_FAILURE,
  FETCH_LICENSES_REQUEST,
  FETCH_LICENSES_SUCCESS,
  FETCH_TEXT_FAILURE,
  FETCH_TEXT_SUCCESS,
  USER_ORDER_FIELDS_CHANGE,
  USER_POST_DRAFT_FAILURE,
  USER_POST_DRAFT_REQUEST,
  USER_POST_DRAFT_SUCCESS,
  USER_PURCHASE_FAILURE,
  USER_PURCHASE_REQUEST,
  USER_PURCHASE_SUCCESS,
  SET_SEND_ME_MORE,
} from '../actionTypes';

const cachedFields = sessionStorage.getItem('fields');

const fields = cachedFields
  ? JSON.parse(cachedFields)
  : {
    firstName: {
      value: '',
    },
    lastName: {
      value: '',
    },
    phoneNumber: {
      value: '',
    },
    clientEmail: {
      value: '',
    },
    companyName: {
      value: '',
    },
    clientRole: {
      value: '',
    },
    employees: {
    // it's weird to assign undefined, but it's the only way to make select show placeholder
      value: undefined,
      options: [
        {
          value: '1',
          title: '1–10',
        },
        {
          value: '11',
          title: '11–100',
        },
        {
          value: '101',
          title: '101–500',
        },
        {
          value: '500',
          title: '500+',
        },
      ],
    },
    website: {
      value: '',
    },
    officeAddress1: {
      value: '',
    },
    officeAddress2: {
      value: '',
    },
    city: {
      value: '',
    },
    country: {
    // it's weird to assign undefined, but it's the only way to make select show placeholder
      value: undefined,
      options: [],
    },
    zipCode: {
      value: '',
    },
    sendMore: {
      value: false,
    },
  };

const initialState = {
  licenses: [],
  freeLicenseCount: 0,
  fields,
  price: {
    symplete: {
      id: 1,
      count: 0,
      price: 50,
      discount: 0.5,
      name: 'Symplete',
      key: 'symplete',
      min: 1,
    },
    hr: {
      id: 3,
      count: 0,
      price: 20,
      discount: 0.5,
      name: 'HR',
      key: 'hr',
      min: 0,
    },
    tickets: {
      id: 4,
      count: 0,
      price: 20,
      discount: 0.5,
      name: 'Customer Service',
      key: 'tickets',
      min: 0,
    },
  },
  draft: {
    CompanyName: '',
    Email: '',
    FirstName: '',
    LastName: '',
    Payload: {},
    PhoneNumber: '',
    Token: '',
  },
  user: {
    login: '',
    password: '',
    clientId: '',
    invoiceNumber: '',
    invoiceHash: '',
  },
  isAccountBeingCreated: false,
  isChecked: false,
  isError: false,
  isCustomer: false,
  // isFree: false,
  isFree: true,
  productsList: [
    {
      title: 'Sales',
    },
    {
      title: 'Tickets',
    },
    {
      title: 'HR',
    },
    {
      title: 'Tasks',
    },
    {
      title: 'Knowledge Base',
    },
    {
      title: 'Moods',
    },
    {
      title: 'To Do List',
    },
    {
      title: 'Dashboards',
    },
    {
      title: 'Company Notices',
    },
    {
      title: 'CustomerDB',
      subtitle: '(coming 2020)',
    },
    {
      title: 'Cashflow',
      subtitle: '(coming 2020)',
    },
    {
      title: 'Surveys',
      subtitle: '(coming 2020)',
    },
  ],

  texts: {
    terms: 'Terms and conditions',
    privacy: 'Privacy notes',
  },

  accountCreationHasFailed: false,
};

const reducers = {
  [USER_ORDER_FIELDS_CHANGE]: (state, { payload }) => ({
    ...state,
    fields: {
      ...state.fields,
      ...payload,
    },
  }),

  symplete: (state, { payload }) => ({
    ...state,
    price: {
      ...state.price,
      symplete: {
        ...state.price.symplete,
        count: payload,
      },
    },
  }),

  hr: (state, { payload }) => ({
    ...state,
    price: {
      ...state.price,
      hr: {
        ...state.price.hr,
        count: payload,
      },
    },
  }),

  tickets: (state, { payload }) => ({
    ...state,
    price: {
      ...state.price,
      tickets: {
        ...state.price.tickets,
        count: payload,
      },
    },
  }),

  sales: (state, { payload }) => ({
    ...state,
    price: {
      ...state.price,
      sales: {
        ...state.price.sales,
        count: payload,
      },
    },
  }),

  tasks: (state, { payload }) => ({
    ...state,
    price: {
      ...state.price,
      tasks: {
        ...state.price.tasks,
        count: payload,
      },
    },
  }),

  [USER_POST_DRAFT_REQUEST]: (state) => ({
    ...state,
  }),

  [USER_POST_DRAFT_SUCCESS]: (state, { payload }) => ({
    ...state,
    draft: {
      Payload: {},
      ...payload,
    },
  }),

  [USER_POST_DRAFT_FAILURE]: (state) => ({
    ...state,
  }),

  [USER_PURCHASE_REQUEST]: (state) => ({
    ...state,
    isAccountBeingCreated: true,
  }),

  [USER_PURCHASE_SUCCESS]: (state, { payload }) => ({
    ...state,
    user: {
      ...payload,
    },
    isAccountBeingCreated: false,
  }),

  [USER_PURCHASE_FAILURE]: (state) => ({
    ...state,
    accountCreationHasFailed: true,
    isAccountBeingCreated: false,
  }),

  [CUSTOMER_CHECKBOX_CHECK]: (state, { payload }) => ({
    ...state,
    isChecked: payload,
  }),

  [CUSTOMER_SET_MIN_COUNT]: (state, { payload }) => ({
    ...state,
    price: {
      ...state.price,
      symplete: {
        ...state.price.symplete,
        min: payload.symplete,
      },
      hr: {
        ...state.price.hr,
        min: payload.hr,
      },
      tickets: {
        ...state.price.tickets,
        min: payload.tickets,
      },
    },
  }),

  [CHECKBOX_TC_ERROR]: (state, { payload }) => ({
    ...state,
    isError: payload,
  }),

  [CUSTOMER_SET_IS_CUSTOMER]: (state, { payload }) => ({
    ...state,
    isCustomer: payload,
  }),

  [FETCH_LICENSES_REQUEST]: (state) => ({ ...state }),

  // Free mode is active if first element of licenses array has equal price and discount
  [FETCH_LICENSES_SUCCESS]: (state, { payload }) => ({
    ...state,
    licenses: [...payload],
    // isFree: payload[0].PricePerUser === payload[0]['Discount.DiscountedPrice'],
  }),

  [FETCH_LICENSES_FAILURE]: (state) => ({ ...state }),

  [FETCH_FREE_LICENSES_SUCCESS]: (state, { payload }) => ({
    ...state,
    freeLicenseCount: payload || 0,
    // isFree: payload[0].PricePerUser === payload[0]['Discount.DiscountedPrice'],
  }),

  [FETCH_FREE_LICENSES_FAILURE]: (state) => ({ ...state }),

  [FETCH_TEXT_FAILURE]: (state) => ({ ...state }),

  [FETCH_TEXT_SUCCESS]: (state, { payload }) => ({
    ...state,
    texts: {
      ...state.texts,
      ...payload,
    },
  }),

  [SET_SEND_ME_MORE]: (state) => {
    const update = {
      sendMore: {
        value: true,
      },
    };

    const updatedfields = { ...state.fields, ...update };

    return {
      ...state,
      fields: updatedfields,
    };
  },
};

const userReducer = (
  state = initialState,
  action,
) => connectReducers(reducers, state, action);

export default userReducer;
