import urlParser from 'url-parse';
import { BILLING_PERIOD_TYPES } from 'constants/billing';
import connectReducers from 'store/connectReducers';
import {
  SHOW_CREDIT_CARD_FORM,
  HIDE_CREDIT_CARD_FORM,
  SET_STRIPE_CUSTOMER_ID,
  SET_STRIPE_CUSTOMER_SECRET,
  SET_CONFIRMED_NAME_ON_CARD,
} from 'store/actionTypes';

const getBillingPeriodTypeFromQs = () => {
  const pageUrl = urlParser(window.location.href, true);
  const qsBillingPeriodType = pageUrl.query.billing_period_type;

  const defaultBillingPeriodType = BILLING_PERIOD_TYPES.monthly;
  const billingPeriodType = (
    BILLING_PERIOD_TYPES[qsBillingPeriodType] || defaultBillingPeriodType
  );

  return billingPeriodType;
};

const initialState = {
  isVisible: false,
  stripeCustomerSecret: null,
  stripeCustomerId: null,
  billingPeriodType: getBillingPeriodTypeFromQs(),
  confirmedNameOnCard: null,
};

const reducers = {
  [SHOW_CREDIT_CARD_FORM]: (state) => ({
    ...state,
    isVisible: true,
  }),

  [HIDE_CREDIT_CARD_FORM]: (state) => ({
    ...state,
    isVisible: false,
  }),

  [SET_STRIPE_CUSTOMER_ID]: (state, { payload }) => ({
    ...state,
    stripeCustomerId: payload,
  }),

  [SET_STRIPE_CUSTOMER_SECRET]: (state, { payload }) => ({
    ...state,
    stripeCustomerSecret: payload,
  }),

  [SET_CONFIRMED_NAME_ON_CARD]: (state, { payload }) => ({
    ...state,
    confirmedNameOnCard: payload,
  }),
};

const creditForm = (
  state = initialState,
  action,
) => connectReducers(reducers, state, action);

export default creditForm;
