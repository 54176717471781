import GlobalApiConnector from 'api/connectors/GlobalApiConnector';

import globalEndpoints from 'api/endpoints/global';

export const endpoints = {
  global: globalEndpoints,
};

const apiConnector = {
  global: new GlobalApiConnector(),
};

export default apiConnector;
