import connectReducers from 'store/connectReducers';
import {
  SET_CLIENT_SOURCE,
  SET_LANDING_VERSION,
} from '../actionTypes';


const initialState = {
  clientSource: null,
  landingVersion: null,
};

const reducers = {
  [SET_CLIENT_SOURCE]: (state, action) => ({
    ...state,
    clientSource: action.payload,
  }),
  [SET_LANDING_VERSION]: (state, action) => ({
    ...state,
    landingVersion: action.payload,
  }),
};

const marketing = (
  state = initialState,
  action,
) => connectReducers(reducers, state, action);

export default marketing;
