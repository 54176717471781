import React from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';

import {
  Root,
} from './styledItems';

const Flex = (props) => {
  const {
    children,
    justifyContent,
    className,
    alignItems,
    flexWrap,
    spacing,
    spacingStep,
    style,
    fullWidth,
    flexDirection,
  } = props;

  const gap = spacing * spacingStep;

  const items = React.Children.toArray(children);

  return (
    <Root
      {...props} // eslint-disable-line react/jsx-props-no-spreading
      fullWidth={fullWidth}
      className={classnames({
        [className]: className,
      })}
      justifyContent={justifyContent}
      alignItems={alignItems}
      flexWrap={flexWrap}
      gap={gap}
      style={style}
      flexDirection={flexDirection}
    >
      {items.map((item) => React.cloneElement(item, { gap }))}
    </Root>
  );
};

Flex.defaultProps = {
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  flexWrap: 'nowrap',
  spacing: 0,
  spacingStep: 8,
  children: [],
  flexDirection: 'row',
  className: null,
};

const {
  arrayOf,
  element,
  number,
  oneOfType,
  string,
} = PropTypes;

Flex.propTypes = {
  children: oneOfType([element, arrayOf(element)]),
  justifyContent: string,
  alignItems: string,
  flexWrap: string,
  spacing: number,
  spacingStep: number,
  flexDirection: string,
  className: string,
};

export default Flex;
