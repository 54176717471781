import React from 'react';
import PropTypes from 'prop-types';

import {
  Spin,
  SpinnerWrapper,
} from './styledItems';


const SpinnerBackdrop = (props) => {
  const {
    isVisible,
  } = props;

  if (!isVisible) return null;

  return (
    <SpinnerWrapper>
      <Spin />
    </SpinnerWrapper>
  );
};


SpinnerBackdrop.defaultProps = {
  isVisible: false,
};

const {
  bool,
} = PropTypes;

SpinnerBackdrop.propTypes = {
  isVisible: bool,
};

export default SpinnerBackdrop;
