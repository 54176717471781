import axios from 'axios';

class BaseApiConnector {
  /**
   *
   * @param {string} baseURL API connection string
   *
   * @param {'token' } authenticationType
   */
  constructor(baseURL, contentType = 'application/json', authenticationType = 'token') {
    this.axiosInstance = axios.create({
      baseURL,
    });

    this.authenticationType = authenticationType;
    this.contentType = contentType;
  }

  token() {
    return localStorage.getItem(this.authenticationType);
  }

  headers(token) {
    const headers = {
      'Content-Type': this.contentType,
      authorization: token || this.token(),
    };

    return headers;
  }

  /**
   *
   * @param {string} endpoint API endpoint
   * @param {Object} params query parameters
   */
  get(endpoint, params) {
    const headers = this.headers();

    const requestConfig = {
      headers,
    };

    const requestConfigWithParams = {
      ...requestConfig,
      params,
    };

    return this.axiosInstance.get(
      endpoint,
      requestConfigWithParams,
    );
  }

  /**
   *
   * @param {string} endpoint API endpoint
   * @param {Object} data request body
   */
  post(endpoint, data, token) {
    const requestConfig = {
      headers: this.headers(token),
    };

    return this.axiosInstance.post(
      endpoint,
      data,
      requestConfig,
    );
  }

  /**
   *
   * @param {string} endpoint API endpoint
   * @param {Object} data request body
   */
  put(endpoint, data) {
    const requestConfig = {
      headers: this.headers(),
    };

    return this.axiosInstance.put(
      endpoint,
      data,
      requestConfig,
    );
  }

  /**
   *
   * @param {string} endpoint API endpoint
   */
  delete(endpoint, data) {
    return this.axiosInstance.delete(
      endpoint,
      {
        headers: this.headers(),
        data,
      },
    );
  }
}

export default BaseApiConnector;
