import {
  useState,
  useMemo,
} from 'react';

import requests from 'api/requests';

const usePendingClientPromotion = (token) => {
  const [clientData, setClientData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const promote = useMemo(() => async () => {
    console.log(token)

    setIsLoading(true);
    const result = await requests.external.offers.accept(token);
    setIsLoading(false);

    console.log(result)

    setClientData(result);
  }, [
    token,
  ]);

  return [promote, clientData, isLoading];
};

export default usePendingClientPromotion;
