import connectReducers from 'store/connectReducers';

import {
  DETAILS_FORM_FIELDS_CHANGE,
  SET_STALLED_STAGE_ID,
  SET_SPECIAL_OFFER_CODE,
  SET_ACCOUNT_EXISTS,
  SET_SUBMITTED,
} from 'store/actionTypes';

const cachedFields = sessionStorage.getItem('detailsForm');

const fields = cachedFields
  ? {
    ...JSON.parse(cachedFields),
    discountCode: {
      value: '',
    },
    subscriptionType: {
      value: '1',
    },
  } : {
    firstName: {
      value: '',
    },
    lastName: {
      value: '',
    },
    clientEmail: {
      value: '',
    },
    phoneNumber: {
      value: '',
    },
    companyName: {
      value: '',
    },
    realtorId: {
      value: '',
    },
    discountCode: {
      value: '',
    },
    subscriptionType: {
      value: '1',
    },
    submitted: false,
    stalledStage: null,
  };

const initialState = {
  ...fields,
  specialOfferCode: null,
  accountExists: null,
};

const reducers = {
  [SET_STALLED_STAGE_ID]: (state, { stalledStage }) => ({
    ...state,
    stalledStage,
  }),

  [SET_SUBMITTED]: (state, { submitted }) => ({
    ...state,
    submitted,
  }),

  [DETAILS_FORM_FIELDS_CHANGE]: (state, { payload }) => ({
    ...state,
    ...payload,
  }),

  [SET_SPECIAL_OFFER_CODE]: (state, action) => ({
    ...state,
    specialOfferCode: action.payload,
  }),

  [SET_ACCOUNT_EXISTS]: (state, action) => ({
    ...state,
    accountExists: action.payload,
  }),
};

const detailsForm = (
  state = initialState,
  action,
) => connectReducers(reducers, state, action);

export default detailsForm;
