// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles_topMenu__3N6BL {\n  font-weight: 400;\n  font-family: 'Poppins', 'Roboto', 'Lato', Helvetica, Arial, sans-serif;\n  font-size: 16px; }\n  .styles_topMenu__3N6BL a {\n    color: #35363e; }\n  .styles_topMenu__3N6BL .styles_underlinedLink__1dtdq {\n    text-decoration: underline; }\n  .styles_topMenu__3N6BL .styles_submenu-title-wrapper__2dW5V {\n    font-weight: 500; }\n\n.styles_menuItem__3Sj0X {\n  color: #35363e;\n  font-family: 'Poppins', 'Roboto', 'Lato', Helvetica, Arial, sans-serif; }\n", ""]);
// Exports
exports.locals = {
	"topMenu": "styles_topMenu__3N6BL",
	"underlinedLink": "styles_underlinedLink__1dtdq",
	"submenu-title-wrapper": "styles_submenu-title-wrapper__2dW5V",
	"menuItem": "styles_menuItem__3Sj0X"
};
module.exports = exports;
