// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles_page__3hJq7 {\n  display: flex;\n  justify-content: center;\n  background-repeat: no-repeat;\n  background-size: cover;\n  background-position: center; }\n\n.styles_main__Auh0M {\n  background: none;\n  display: flex;\n  height: 100vh;\n  max-width: 1366px; }\n\n.styles_content__2KTyL {\n  flex: 1 0 auto;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  margin-bottom: 30px; }\n  @media (max-width: 768px) {\n    .styles_content__2KTyL {\n      padding: 20px; } }\n\n.styles_greeting__2nuPT {\n  font-size: 23px;\n  font-weight: 700;\n  margin-bottom: 20px; }\n\n.styles_subgreeting__25HnW {\n  font-size: 20px;\n  font-weight: 700;\n  margin-bottom: 0; }\n\n.styles_detailsBox__2CO6z {\n  margin: 50px 0;\n  display: flex;\n  flex-direction: column;\n  padding: 35px 75px;\n  border-radius: 4px;\n  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.2);\n  border: solid 2px #3B2A80;\n  background-color: #ffffff; }\n  @media (max-width: 375px) {\n    .styles_detailsBox__2CO6z {\n      padding: 35px 40px; } }\n\n.styles_detailsItem__3cm4a {\n  font-size: 18px; }\n\n.styles_startButton__M9Kma {\n  align-self: center;\n  width: 195px;\n  margin-top: 5px;\n  height: 40px;\n  border-radius: 22px;\n  background: #3B2A80;\n  border: none;\n  text-transform: uppercase; }\n\n.styles_text__2NwVw {\n  text-align: center;\n  margin-bottom: 10px;\n  max-width: 400px; }\n\n.styles_warningBox___hnGE {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  padding: 100px 0; }\n", ""]);
// Exports
exports.locals = {
	"page": "styles_page__3hJq7",
	"main": "styles_main__Auh0M",
	"content": "styles_content__2KTyL",
	"greeting": "styles_greeting__2nuPT",
	"subgreeting": "styles_subgreeting__25HnW",
	"detailsBox": "styles_detailsBox__2CO6z",
	"detailsItem": "styles_detailsItem__3cm4a",
	"startButton": "styles_startButton__M9Kma",
	"text": "styles_text__2NwVw",
	"warningBox": "styles_warningBox___hnGE"
};
module.exports = exports;
