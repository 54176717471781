import {
  SET_LANDING_VERSION,
} from '../actionTypes';

export const setLandingVersion = (version) => ({
  type: SET_LANDING_VERSION,
  payload: version,
});

export default {
  setLandingVersion,
};
