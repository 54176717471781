const colors = {
  blue: '#367BED',

  green: '#25C86A',
  orange: '#FFCC00',
  red: '#FC5838',

  'lightest-gray': '#FCFCFC',
  'lightest-grey': '#FCFCFC',
  'light-gray': '#F8F9FA',
  'light-grey': '#F8F9FA',
  'bluish-gray': '#F2F6FE',
  'bluish-grey': '#F2F6FE',
  'dark-bluish-gray': '#D8E5FF',
  'dark-bluish-grey': '#D8E5FF',

  'deep-blue': '#141736',
  'deep-blue-transparent': 'rgba(20, 23, 54, 0.4)',

  gray: '#c1c1c1',
  grey: '#c1c1c1',

  'dark-gray': '#616161',
  'dark-grey': '#616161',

  white: '#ffffff',

  violet: '#9013FE',
  watermelon: '#FF526B',
  malibu: '#77a8f8',

  'black-transparent': 'rgba(0, 0, 0, 0.87)',
  'black-transparent-1': 'rgba(0, 0, 0, 0.87)',
  'black-transparent-2': 'rgba(0, 0, 0, 0.47)',
  'black-transparent-3': 'rgba(0, 0, 0, 0.17)',

  'white-transparent': 'rgba(255, 255, 255, 0.07)',
  'white-transparent-1': 'rgba(255, 255, 255, 0.07)',
  'white-transparent-2': 'rgba(255, 255, 255, 0.27)',
};

const palette = {
  // Symplete UI kit semantic colors
  focus: colors.blue,
  'focus-inversed': colors.malibu,

  success: colors.green,
  warning: colors.orange,
  alert: colors.red,
  disabled: colors.gray,

  'main-text': colors['deep-blue'],
  'main-text-inversed': colors.white,

  'descriptor-text': colors['dark-gray'],
  placeholder: colors['deep-blue-transparent'],
  empty: colors.gray,

  background: colors.white,
  'background-hover': colors['lightest-gray'],
  'background-app': colors['deep-blue'],
  'background-overlay-light': colors['white-transparent'],
  'background-overlay-dark': colors['black-transparent-3'],
  'background-flat': colors['bluish-gray'],
  'background-flat-dark': colors['dark-bluish-gray'],
  'placeholder-background': colors['dark-bluish-grey'],
  'divider-light': colors['white-transparent-2'],

  //
  new: colors.watermelon,
  followup: colors.violet,
  //

  // roles
  buyer: colors.watermelon,
  seller: colors.violet,
  'buyer/seller': `linear-gradient(90deg, ${colors.watermelon} 0%, ${colors.violet} 100%)`,
  'past client': colors['dark-gray'],
  vip: colors.green,
  //

  // property statuses
  available: colors.green,
  'under offer': colors.violet,
  'off market': colors.gray,
  'in transaction': colors.watermelon,
  sold: colors.malibu,
  //

  // property other parameters
  'my listing': colors.violet,
  featured: colors['deep-blue'],
  'off-market listing': colors['dark-gray'],
  reo: colors.green,

  delimiter: colors['dark-bluish-gray'],
  'input-border': colors['dark-bluish-gray'],

  // Legacy semantic colors
  default: colors['deep-blue'],
  primary: colors['deep-blue'],
  additional: colors['dark-gray'],

  ghost: colors['dark-gray'],

  link: colors.blue,
  error: colors.red,
  highlight: colors.blue,
  attention: colors.red,
  hover: colors['light-gray'],
  contrast: colors.white,
  selected: colors.green,

  cold: colors.blue,
  warm: colors.orange,
  hot: colors.red,

  // placeholder: colors['black-transparent-2'],
  border: colors['dark-bluish-grey'],

  calendarShowings: colors['deep-blue-transparent'],
  calendarListingPitches: colors.red,
  calendarFollowUpReminders: colors.blue,
  calendarClosingEscrows: colors.violet,
  calendarContingenciesDue: colors.orange,

  // days
  weekend: colors.red,
};

const shadows = {
  modal: '0 4px 8px 2px rgba(9, 30, 66, 0.25), 0 0 1px 0 rgba(9, 30, 66, 0.31)',
  elevated: '0 2px 9px 0 rgba(9, 55, 83, 0.1)',
  button: '0 2px 9px 0 rgba(0, 0, 0, 0.1)',
  dropdown: '0 4px 8px 2px rgba(9, 55, 83, 0.1), 0 0 1px 0 rgba(9, 55, 83, 0.3)',
};

const fonts = {
  default: 'Roboto, Helvetica, Arial, sans-serif',
  primary: 'Roboto, Helvetica, Arial, sans-serif',
  secondary: 'Lato, Helvetica, Arial, sans-serif',
};

const fontWeights = {
  light: 300,
  regular: 400,
  bold: 500,
  black: 700,
};

const typography = {
  fontFamily: {
    default: fonts.default,
    primary: fonts.primary,
    secondary: fonts.secondary,

    h1: fonts.primary,
    heading: fonts.primary,

    h2: fonts.primary,
    title1: fonts.primary,

    h3: fonts.primary,
    title2: fonts.primary,
    button: fonts.primary,

    h4: fonts.primary,
    title3: fonts.primary,

    tableHeader: fonts.primary,

    h5: fonts.primary,
    title4: fonts.primary,
    caption: fonts.primary,
    caption1: fonts.primary,
    subtitle: fonts.primary,
    subtitle1: fonts.primary,

    h6: fonts.primary,
    caption2: fonts.primary,
    subtitle2: fonts.primary,

    body: fonts.primary,
    body1: fonts.primary,
    body2: fonts.primary,

    link: fonts.primary,

    tag: fonts.primary,
    tag2: fonts.primary,

    chip: fonts.primary,
    label: fonts.primary,

    cell: fonts.primary,
    cell2: fonts.primary,
  },

  fontSize: {
    default: '15px',

    h1: '34px',
    heading: '34px',

    h2: '24px',
    title1: '24px',

    h3: '18px',
    title2: '18px',
    button: '18px',

    h4: '15px',
    title3: '15px',
    tableHeader: '15px',

    h5: '13px',
    title4: '13px',
    caption: '13px',
    caption1: '13px',
    subtitle: '13px',
    subtitle1: '13px',

    h6: '10px',
    caption2: '10px',
    subtitle2: '10px',

    body: '15px',
    body1: '15px',

    body2: '13px',

    link: '15px',
    cell: '15px',

    label: '13px',
    tag: '13px',

    cell2: '12px',

    tag2: '10px',

    chip: '13px',
  },

  lineHeight: {
    default: '15px',

    h1: '37px',
    heading: '37px',

    h2: '26px',
    title1: '26px',

    h3: '24px',
    title2: '24px',

    h4: '22px',
    title3: '22px',
    button: '22px',
    tableHeader: '22px',

    h5: '15px',
    title4: '15px',
    caption: '15px',
    caption1: '15px',
    subtitle: '15px',
    subtitle1: '15px',

    h6: '12px',
    caption2: '15px',
    subtitle2: '12px',

    body: '17px',
    body1: '17px',

    body2: '15px',

    link: '17px',
    cell: '17px',

    label: '15px',
    tag: '15px',

    cell2: '13px',

    tag2: '11px',

    chip: '15px',
  },

  fontWeight: {
    default: fontWeights.regular,

    h1: fontWeights.bold,
    heading: fontWeights.bold,

    h2: fontWeights.bold,
    title1: fontWeights.bold,

    h3: fontWeights.bold,
    title2: fontWeights.bold,

    h4: fontWeights.regular,
    title3: fontWeights.regular,
    button: fontWeights.regular,

    h5: fontWeights.regular,
    title4: fontWeights.regular,
    caption: fontWeights.regular,
    caption1: fontWeights.regular,
    subtitle: fontWeights.regular,
    subtitle1: fontWeights.regular,

    tableHeader: fontWeights.bold,

    h6: fontWeights.regular,
    caption2: fontWeights.regular,
    subtitle2: fontWeights.regular,

    body: fontWeights.regular,
    body1: fontWeights.regular,

    body2: fontWeights.regular,

    cell: fontWeights.regular,
    cell2: fontWeights.regular,

    link: fontWeights.regular,
    label: fontWeights.regular,

    tag: fontWeights.bold,
    tag2: fontWeights.bold,

    chip: fontWeights.regular,
  },

  marginBottom: {
    default: '11px',

    h1: '20px',
    heading: '20px',

    h2: '18px',
    title1: '18px',

    h3: '16px',
    title2: '16px',
    button: '0',

    h4: '10px',
    title3: '10px',

    h5: '12px',
    title4: '12px',
    caption: '12px',
    caption1: '12px',
    subtitle: '12px',
    subtitle1: '12px',

    h6: '11px',
    caption2: '11px',
    subtitle2: '11px',

    body: '11px',
    body1: '11px',

    body2: '8px',

    label: '11px',
    link: '11px',
    cell: '0',
    cell2: '0',

    tag: '0',
    tag2: '0',

    chip: '0',
  },

  textDecoration: {
    default: 'none',
    link: 'underline',
  },

  fontStyle: {
    default: 'normal',
  },
};

const iconsType = 'feather';

const iconSizes = {
  xxxs: 8,
  xxs: 12,
  xs: 14,

  sm: 18,
  s: 18,

  m: 22,
  md: 22,

  l: 26,
  lg: 26,

  xl: 32,
  xxl: 62,
};

export const theme = {
  colors,
  palette,
  shadows,

  fonts,
  fontWeights,
  typography,
  iconsType,
  iconSizes,
};

export default theme;
