// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles_main__XBVxO {\n  min-height: 100px;\n  flex-shrink: 0;\n  padding: 20px 10px 30px 10px;\n  background: none;\n  display: flex;\n  flex-direction: column; }\n\n.styles_logoSection__3ft3e {\n  order: 0;\n  display: flex;\n  flex-direction: column; }\n\n.styles_sympleteLogoLink__KrRmG {\n  display: block; }\n", ""]);
// Exports
exports.locals = {
	"main": "styles_main__XBVxO",
	"logoSection": "styles_logoSection__3ft3e",
	"sympleteLogoLink": "styles_sympleteLogoLink__KrRmG"
};
module.exports = exports;
