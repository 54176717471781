import React from 'react';

import {
  Menu,
  Icon,
} from 'antd';

import styles from './styles.module.scss';

const { SubMenu } = Menu;

const rootStyle = {
  textAlign: 'right',
  borderBottom: 'none',
  color: '#35363e',
};

const submenuItems = [
  {
    key: 'Lead Generation & Marketing',
    title: 'Lead Generation & Marketing',
    linkTo: '/lead-generation-and-marketing',
  },
  {
    key: 'Digital Personal Assistant',
    title: 'Digital Personal Assistant',
    linkTo: '/digital-personal-assistant',
  },
  {
    key: 'crm+',
    title: 'Agent Specific CRM+',
    linkTo: '/crm-plus',
  },
  {
    key: 'Offer Management',
    title: 'Offer Management',
    linkTo: '/offer-management',
  },
  {
    key: 'Transaction Management',
    title: 'Transaction Management',
    linkTo: '/transaction-management',
  },
  {
    key: 'Community Network',
    title: 'Community Network',
    linkTo: '/community-network',
  },
  {
    key: 'Coming Soon',
    title: 'Coming Soon',
    linkTo: '/coming-soon',
  },
];

const HeaderMenu = (props) => (
  <Menu className={styles.topMenu} mode="horizontal" style={rootStyle}>
    <Menu.Item key="Why Symplete">
      {/* eslint-disable-next-line react/jsx-no-target-blank */}
      <a className={styles.menuItem} href="https://www.symplete.com/why-symplete" target="_blank">Why Symplete</a>
    </Menu.Item>

    <SubMenu
      key="Products"
      title={(
        <span className={styles['submenu-title-wrapper']}>
          Features
          <Icon
            type="down"
            style={{
              fontSize: '12px',
              marginLeft: 5,
            }}
          />
        </span>
      )}
    >
      {
        submenuItems.map((item) => (
          <Menu.Item key={item.key}>
            {/* eslint-disable-next-line react/jsx-no-target-blank */}
            <a
              className={styles.menuItem}
              href={`https://www.symplete.com${item.linkTo}`}
              target="_blank"
            >
              {item.title}
            </a>
          </Menu.Item>
        ))
      }
    </SubMenu>
  </Menu>
);

export default HeaderMenu;
