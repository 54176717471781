// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles_main-row__1-hRV {\n  height: 100%;\n  width: 100%; }\n\n.styles_col__bg__He1Hp {\n  display: flex;\n  flex-direction: column;\n  background-color: #EDECF0;\n  background-size: contain;\n  background-position: right;\n  background-repeat: no-repeat; }\n\n.styles_col__form__31DP4 {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  background-color: #ffffff; }\n", ""]);
// Exports
exports.locals = {
	"main-row": "styles_main-row__1-hRV",
	"col__bg": "styles_col__bg__He1Hp",
	"col__form": "styles_col__form__31DP4"
};
module.exports = exports;
