import React from 'react';


const IconPrefixWrapper = ({ icon, children }) => {
    const iconStyle = {
        position: 'absolute',
        top: '22px',
        left: '18px',
        transform: 'translate(-50%, -50%)'
    };

    return (
        <div style={{ position: 'relative' }}>
            {children}
            {icon && <span style={iconStyle}>{icon}</span>}
        </div>
    );
};

export default IconPrefixWrapper;
