import {
  SET_CLIENT_SOURCE,
} from '../actionTypes';

export const setClientSource = (source) => ({
  type: SET_CLIENT_SOURCE,
  payload: source,
});

export default {
  setClientSource,
};
