import React from 'react';
import { Row, Col } from 'antd';
import DetailsForm from 'pages/SignupV3/DetailsForm';
import { FormWrapper } from './styledItems';
import styles from './styles.module.scss';
import backgroundImage from 'assets/images/login/login-image.png';

const YourDetailsSection = () => (
  <Row type='flex' className={styles['main-row']}>
    <Col xs={24} md={12} className={styles['col__bg']}
      style={{
        backgroundImage: `url('${backgroundImage}')`,
      }}
    >
    </Col>
    <Col xs={24} md={12} className={styles['col__form']}>
      <FormWrapper>
        <DetailsForm />
      </FormWrapper>
    </Col>
  </Row>
);

export default YourDetailsSection;
