import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';

import {
  Button,
  Form,
  Row,
  Col,
} from 'antd';

import {
  FormTitle,
  AccountExistsWarning,
  OfficialStatement,
} from './styledItems';

import styles from './styles.module.scss';
import Typography from 'ui/Typography';
import purpleStarsIcon from 'assets/icons/purple-stars.svg';
import googleIcon from 'assets/icons/google-icon.svg';
import microsoftIcon from 'assets/icons/microsoft-icon.svg';
import phoneInputIcon from 'assets/icons/phone-input-icon.svg';
import mailInputIcon from 'assets/icons/mail-input-icon.svg';
import nameInputIcon from 'assets/icons/name-input-icon.svg';
import IconPrefixWrapper from 'ui/InputPrefixWrapper';
import {
  getFirstNameError,
  getLastNameError,
  getEmailError,
  getPhoneError,
  getErrorWarning,
} from 'utils/signUpValidations'
import handleEnterKey from 'utils/signUpEnterKey';
import { handlePhoneNumberChange } from 'utils/signUpHandlePhoneChange';
import { setDetailsFormFields } from 'store/actions/detailsForm';
import { useDispatch } from 'react-redux';


const logInLink = process.env.REACT_APP_SYMPLETE_CLIENT_APP_URL;
const existingUserLink = `${logInLink}/login`;

const DetailsForm = Form.create({
  name: 'details_form',

  onFieldsChange(props, fields) {
    props.setFields(fields);
  },

  mapPropsToFields(props) {
    const { fields } = props;
    return {
      firstName: Form.createFormField({
        ...fields.firstName,
        value: fields.firstName.value,
      }),
      lastName: Form.createFormField({
        ...fields.lastName,
        value: fields.lastName.value,
      }),
      clientEmail: Form.createFormField({
        ...fields.clientEmail,
        value: fields.clientEmail.value,
      }),
      phoneNumber: Form.createFormField({
        ...fields.phoneNumber,
        value: fields.phoneNumber.value,
      }),
    };
  },
  onValuesChange() { },
})(
  ({
    form,
    className,
    history,
    fields,
    saveUserDataDraft,
    isError,
    onSubmit,
    checkIfAccountExists,
    accountExists,
  }) => {
    const dispatch = useDispatch();
    const [errors, setErrors] = useState({})
    const firstNameInput = useRef(null);
    const lastNameInput = useRef(null);
    const phoneInput = useRef(null);
    const emailInput = useRef(null);
    const nextButton = useRef(null);

    const draft = {
      Payload: {},
      Email: fields.clientEmail.value,
      FirstName: fields.firstName.value,
      LastName: fields.lastName.value,
      PhoneNumber: fields.phoneNumber.value,
    };

    useEffect(() => {
      window.signUpForm = form;
    }, [form]);

    const validateFirstName = () => setErrors({
      ...errors, firstName: getFirstNameError(firstNameInput.current.value)
    });
    const validateLastName = () => setErrors({
      ...errors, lastName: getFirstNameError(lastNameInput.current.value)
    });
    const validateEmail = () => {
      checkEmail(emailInput.current.value);
      setErrors({
        ...errors, clientEmail: getFirstNameError(emailInput.current.value)
      });
    }
    const validatePhone = () => setErrors({
      ...errors, phoneNumber: getFirstNameError(phoneInput.current.value)
    });

    const validateForm = () => {
      const formErrors = {
        firstName: getFirstNameError(firstNameInput.current.value),
        lastName: getLastNameError(lastNameInput.current.value),
        clientEmail: getEmailError(emailInput.current.value),
        phoneNumber: getPhoneError(phoneInput.current.value)
      }
      if (formErrors.firstName || formErrors.lastName || formErrors.clientEmail || formErrors.phoneNumber) {
        setErrors(formErrors);
        return false;
      }
      setErrors({})
      return true;
    }
    const handleSubmit = (event) => {
      event.preventDefault();
      if (!validateForm()) return;
      if (accountExists) {
        const link = `${existingUserLink}?user=${emailInput.current.value}`;
        window.location.href = link;
        return
      }
      const formFields = {
        firstName: { value: firstNameInput.current.value },
        lastName: { value: lastNameInput.current.value },
        clientEmail: { value: emailInput.current.value },
        phoneNumber: { value: phoneInput.current.value },
      }
      dispatch(setDetailsFormFields(formFields))
      onSubmit();
    };

    const checkEmail = useCallback((email) => {
      checkIfAccountExists(email);
    }, []);

    const validateInputAndGoToNext = (currentFieldName, nextInput) => (event) => {
      form.validateFields([currentFieldName], (err) => {
        if (!err) {
          event.target.blur();
          nextInput.current.focus();
        }
      });
    };

    return (
      <>
        <FormTitle>
          <div className={styles['sign-up-workflow']}>
            <Typography className={styles['sign-up-workflow__welcome-title']}>
              welcome to
            </Typography>
            <Typography className={styles['sign-up-workflow__welcome-title-symplete']}>
              SYMPLETE
              <img
                src={purpleStarsIcon}
                alt="purple stars icon besides text"
                className={styles['sign-up-workflow__welcome-title-symplete-stars']}
              />
            </Typography>
          </div>
        </FormTitle>
        <p className={styles['sign-up-error']}>{getErrorWarning(errors)}</p>
        <Form
          className={classnames(styles.main, className)}
        >
          <Row type='flex' gutter={[8, 16]}>
            <Col span={12}>
              <Form.Item style={{ marginBottom: 6 }}>
                <IconPrefixWrapper icon={
                  <img
                    src={nameInputIcon}
                    alt="name icon"
                    className={styles['sign-up-workflow__name-input-icon']}
                  />
                }>
                  <input
                    ref={firstNameInput}
                    placeholder="First Name *"
                    className={`${styles.formItem} ${errors.firstName ? styles['form-item-error'] : null}`}
                    onBlur={validateFirstName}
                    onKeyDown={handleEnterKey(validateInputAndGoToNext('firstName', lastNameInput))}
                  />
                </IconPrefixWrapper>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item style={{ marginBottom: 6 }}>
                <input
                  ref={lastNameInput}
                  placeholder="Last Name *"
                  className={`${styles.formItem} ${errors.lastName ? styles['form-item-error'] : null}`}
                  onBlur={validateLastName}
                  onKeyDown={handleEnterKey(validateInputAndGoToNext('lastName', emailInput))}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                style={{ marginBottom: 6 }}
              >
                <IconPrefixWrapper icon={
                  <img
                    src={mailInputIcon}
                    alt="mail icon"
                    className={styles['sign-up-workflow__mail-input-icon']}
                  />
                }>
                  <input
                    ref={emailInput}
                    placeholder="Email *"
                    className={`${styles.formItem} ${errors.clientEmail ? styles['form-item-error'] : null}`}
                    onBlur={validateEmail}
                    onKeyDown={handleEnterKey(validateInputAndGoToNext('email', phoneInput))}
                  />
                </IconPrefixWrapper>
              </Form.Item>
            </Col>
            <Col span={12}>
              <IconPrefixWrapper icon={
                <img
                  src={phoneInputIcon}
                  alt="mail icon"
                  className={styles['sign-up-workflow__phone-input-icon']}
                />
              }>
                <input
                  ref={phoneInput}
                  placeholder="Phone *"
                  className={`${styles.formItem} ${errors.phoneNumber ? styles['form-item-error'] : null}`}
                  onChange={handlePhoneNumberChange}
                  onKeyDown={handleEnterKey(validateInputAndGoToNext('phoneNumber', nextButton))}
                  onBlur={validatePhone}
                />
              </IconPrefixWrapper>
            </Col>

            <Col span={24}>
              <Form.Item style={{ marginBottom: 6 }}>
                <Button
                  ref={nextButton}
                  type="primary"
                  className={styles.button}
                  onClick={handleSubmit}
                  onKeyDown={handleSubmit}
                >

                  <img
                    src={googleIcon}
                    alt="google icon"
                    className={styles['sign-up-workflow__google-icon']}
                  /> <b>Continue with Google</b>
                </Button>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item style={{ marginBottom: 6 }}>
                <Button
                  ref={nextButton}
                  type="primary"
                  className={styles.button}
                  onClick={handleSubmit}
                  onKeyDown={handleEnterKey(handleSubmit)}
                >
                  <img
                    src={microsoftIcon}
                    alt="microsoft icon"
                    className={styles['sign-up-workflow__microsoft-icon']}
                  /> <b>Continue with Microsoft</b>
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>

        <OfficialStatement className={styles['sign-up-workflow__if-already-have-an-account-text']}>
          If you already have an account,
          {' '}
          <a
            href={logInLink}
            className={styles['login-link']}
          >log in.</a>
        </OfficialStatement>
      </>
    );
  },
);

DetailsForm.defaultProps = {
  className: null,
  isError: false,
};

const {
  bool,
  func,
  object,
  string,
} = PropTypes;

DetailsForm.propTypes = {
  className: string,
  saveUserDataDraft: func.isRequired,
  fields: object, // eslint-disable-line react/forbid-prop-types
  form: object, // eslint-disable-line react/forbid-prop-types
  history: object, // eslint-disable-line react/forbid-prop-types
  isError: bool,
  setSendMore: func.isRequired,
  onSubmit: func.isRequired,
};

export default DetailsForm;
