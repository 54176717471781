import apiConnector from 'api';

export const createAccount = async (data) => {
  const route = '/v2/create-account';

  const response = await apiConnector.global.post(route, data);

  return response.data;
};

export default createAccount;
