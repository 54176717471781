import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { ThemeContext } from 'styled-components';

import classnames from 'classnames';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as FeatherIcon from 'react-feather'; // eslint-disable-line no-restricted-syntax

import {
  paramCase,
  pascalCase,
} from 'change-case';

import {
  CustomSVG,
  Base,
} from './styledItems';

import customIcons from './customIcons';

const bases = {
  circle: true,
  rounded: true,
  square: true,
};

const Icon = (props) => {
  const {
    className,
    type,
    name,
    color,
    size,
    base,
    baseColor,
  } = props;

  const theme = useContext(ThemeContext);

  const {
    iconsType,
    iconSizes,
  } = theme;

  const currentType = type || iconsType;

  let content = null;

  if (currentType === 'feather') {
    const FIcon = FeatherIcon[pascalCase(name)] || FeatherIcon.Circle;

    content = (
      <FIcon
        icon={name}
        color={theme?.palette?.[color] || theme?.colors?.[color] || color}
        size={iconSizes[size] || size}
      />
    );
  }

  if (currentType === 'fontawesome') {
    content = (
      <FontAwesomeIcon
        icon={paramCase(name)}
        style={{
          color: theme?.palette?.[color] || theme?.colors?.[color] || color,
          fontSize: iconSizes[size] || size,
          width: iconSizes[size] || size,
          height: iconSizes[size] || size,
        }}
      />
    );
  }

  if (currentType === 'custom' && customIcons[name]) {
    content = (
      <CustomSVG
        src={customIcons[name]}
        size={iconSizes[size] || size}
        color={theme?.palette?.[color] || theme?.colors?.[color] || color}
      />
    );
  }

  if (bases[base]) {
    const finalColor = theme?.palette?.[baseColor] || theme?.colors?.[baseColor] || baseColor
      || theme?.palette?.[color] || theme?.colors?.[color] || color;

    return (
      <Base
        className={classnames({
          [className]: className,
        })}
        size={iconSizes[size] || size}
        color={finalColor}
        lighten={!baseColor}
        base={base}
      >
        {content}
      </Base>
    );
  }

  return content;
};

Icon.defaultProps = {
  name: 'circle',
  type: null,
  color: null,
  className: null,
  base: null,
  size: 'm',
  baseColor: null,
};

const {
  number,
  oneOf,
  string,
} = PropTypes;

Icon.propTypes = {
  type: oneOf(['feather', 'fontawesome', 'custom']),
  name: string,
  color: string,
  size: number,
  base: oneOf(['circle', 'rounded', 'square']),
  baseColor: string,
  className: string,
};

export default Icon;
