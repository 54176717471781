import React from 'react';
import PropTypes from 'prop-types';

import padStart from 'lodash/padStart';

import { Layout } from 'antd';

import HeaderOffer from 'pages/common/HeaderOffer';
import Spinner from 'components/SpinnerBackdrop';
import ThemeProvider from 'ui/ThemeProvider';

import FlexItem from 'ui/FlexItem';
import Typography from 'ui/Typography';

import useOffer from './hooks/useOffer';
import usePendingClientPromotion from './hooks/usePendingClientPromotion';

import styles from './styles.module.scss';

import OfferContent from './components/OfferContent';

const { Content } = Layout;

const CustomOffer = (props) => {
  const {
    token,
  } = props;

  const [offer, isOfferLoading] = useOffer(token);
  const [promote, clientData, isAccountBeingCreated] = usePendingClientPromotion(token);
  
  return (
    <ThemeProvider>
      <div className={styles.mainWrapper}>
        <Layout className={styles.main}>
          <HeaderOffer
            offerId={offer?.id && padStart(offer?.id, 7, '0')}
            salesperson={offer?.salesperson_obj}
          />
          {
            (isOfferLoading || isAccountBeingCreated) && (
              <Spinner isVisible />
            )
          }
          {
            !isOfferLoading && !isAccountBeingCreated && !clientData && (
              <Content className={styles.content}>
                <FlexItem fullWidth>
                  <OfferContent 
                    offer={offer} 
                    acceptOffer={promote}
                  />
                </FlexItem>
              </Content>
            )
          }
          {
            clientData && (
              <Content className={styles.content}>
                <div>
                  <Typography variant="h2">
                    Congratulations! All is set up.
                  </Typography>
                  <Typography variant="h3">
                    Your account details:
                  </Typography>
                  <Typography>
                    Client ID:
                    {' '}
                    { clientData?.clientId }
                  </Typography>
                  <Typography>
                    Login:
                    {' '}
                    { clientData?.login }
                  </Typography>
                  <Typography>
                    Password:
                    {' '}
                    { clientData?.password }
                  </Typography>
                  <Typography variant="link">
                    <a href={`${process.env.REACT_APP_SYMPLETE_CLIENT_APP_URL}/login-new?action=new_client&user=${clientData?.login}&tempPass=${clientData?.password}`}>
                      Start using Symplete
                    </a>
                  </Typography>
                </div>
              </Content>
            )
          }
        </Layout>
      </div>
    </ThemeProvider>
  );
};

CustomOffer.defaultProps = {

};

const {
  array,
  arrayOf,
  bool,
  element,
  func,
  instanceOf,
  number,
  object,
  objectOf,
  oneOf,
  oneOfType,
  shape,
  string,
} = PropTypes;

CustomOffer.propTypes = {

};

export default CustomOffer;
