import {
  useState,
  useEffect,
} from 'react';

import requests from 'api/requests';

const useOffer = (token) => {
  const [offer, setOffer] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(async () => {
    const result = await requests.external.offers.get(token);
    setIsLoading(false);
    setOffer(result);
  }, [
    token,
  ]);

  return [offer, isLoading];
};

export default useOffer;
