import apiConnector from 'api';

/**
 * Creates and returns client's StripeId
 * @param {string} email email
 */
const createStripeId = async (email) => {
  const url = '/stripe';

  const response = await apiConnector.global.post(url, {
    email,
  });

  return response.data.stripe_id;
};

export default createStripeId;
