import styled from 'styled-components';

export const Title = styled.h1`
  font-size: 3.5rem;
  font-family: GTEestiProDisplay, Roboto, Arial, sans-serif;
  font-weight: bold;
  color: #fff;
  text-align: center;
  margin-bottom: 0;
`;

export const Subtitle = styled.p`
  font-size: 1.875rem;
  font-family: GTEestiProDisplay, Roboto, Arial, sans-serif;
  font-weight: medium;
  color: #fff;
  text-align: center;
  margin-bottom: 0;
`;

export const InfoText = styled.p`
  font-size: 17px;
  font-weight: 500;
  text-align: center;
  color: #fff;
`;

export const Illustration = styled.img`
  margin-top: 40px;
`;
