// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./assets/fonts/Hind/Hind-Bold.ttf");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "body {\n  margin: 0;\n  padding: 0;\n  font-family: 'Poppins', 'Barlow', 'Lato', Helvetica, Arial, sans-serif;\n  font-weight: normal;\n  font-style: normal;\n  font-stretch: normal;\n  line-height: 1.38;\n  letter-spacing: normal;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale; }\n\nhtml,\nbody {\n  height: 100vh;\n  width: 100vw;\n  overflow: hidden;\n  margin: 0px;\n  padding: 0px; }\n\n@font-face {\n  font-family: \"Hind-Bold\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"opentype\"); }\n", ""]);
// Exports
module.exports = exports;
