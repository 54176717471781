import styled from 'styled-components';

export const FormTitle = styled.div`
  font-size: 2.25rem;
  font-family: GTEestiProDisplay, Roboto, Arial, sans-serif;
  font-weight: bold;
  color: #141637;
  margin-bottom: 20px;
`;

export const AccountExistsWarning = styled.p`
  color: #faad14;
  margin-top: 12px;
  margin-bottom: -2px;
  line-height: normal;


  .symplete-app-link {
    color: inherit;
    text-decoration: underline;
  }
`;

export const OfficialStatement = styled.p`
  font-size: 11px;
  color: #141438;
  margin-top: 15px;
`;

export const OfficialStatementLink = styled.a`
  color: #141438;
  text-decoration: underline;
`;
