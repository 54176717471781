import apiConnector, { endpoints } from 'api';

/**
 * Get special offer code object by code.
 * @param {string} code - The code to find by.
 * @returns {Promise} A response from server.
 */

const getByCode = async (code) => {
  const endpoint = endpoints.global.specialOffersCodePath(code);

  const response = await apiConnector.global.get(endpoint);

  return response.data;
};

export default getByCode;
