import styled from 'styled-components';

export const CallOfficeTitle = styled.p`
  font-size: 21px;
  font-weight: 600;
  text-align: right;
  color: #fff;
  margin-bottom: 5px;
`;

export const PhoneLink = styled.a`
  text-decoration: underline;
  color: #fff;
`;

export const PhoneAvailabilityText = styled.p`
  font-size: 16px;
  font-weight: 600;
  color: #2ecc71;
  text-align: right;
`;
