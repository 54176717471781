import {
  SET_IS_ACCOUNT_BEING_CREATED_YES,
  SET_IS_ACCOUNT_BEING_CREATED_NO,
  SET_CREATION_PROGRESS,
} from 'store/actionTypes';

import connectReducers from 'store/connectReducers';

const initialState = {
  isAccountBeingCreated: false,
  creationProgress: 0,
};

const reducers = {
  [SET_IS_ACCOUNT_BEING_CREATED_YES]: (state) => ({
    ...state,
    isAccountBeingCreated: true,
  }),

  [SET_IS_ACCOUNT_BEING_CREATED_NO]: (state) => ({
    ...state,
    isAccountBeingCreated: false,
  }),

  [SET_CREATION_PROGRESS]: (state, { payload }) => ({
    ...state,
    creationProgress: payload,
  }),
};

const detailsForm = (
  state = initialState,
  action,
) => connectReducers(reducers, state, action);

export default detailsForm;
