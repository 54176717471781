import publicIp from 'public-ip';
import {
  deviceType,
  browserVersion,
  browserName,
} from 'react-device-detect';

const getIP = async () => {
  let ipV4;
  try {
    ipV4 = await publicIp.v4();
  } catch (e) {
    console.error(e);
  }
  return ipV4;
};

export const getUserMeta = async () => {
  const Ip = await getIP();

  return {
    Ip,
    device: deviceType,
    browser: `${browserName} ${browserVersion}`,
  };
};

export default getUserMeta;
