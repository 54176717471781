import React from 'react';

import {
  Row,
  Col,
} from 'antd';

import logo from 'assets/logo.svg';

import HeaderMenu from '../HeaderMenu';

import styles from './styles.module.scss';

const HeaderSimple = (props) => (
  <header className={styles.main}>
    <Row type="flex" align="middle">
      <Col span={12}>
        <div className={styles.logoSection}>
          <a
            className={styles.sympleteLogoLink}
            href="https://symplete.com"
          >
            <img src={logo} height={35} alt="Symplete Realtor logo" />
          </a>
        </div>
      </Col>
      <Col span={12}>
        <HeaderMenu />
      </Col>
    </Row>
  </header>
);

export default HeaderSimple;
