import debounce from 'lodash/debounce';

import {
  DETAILS_FORM_FIELDS_CHANGE,
  SET_STALLED_STAGE_ID,
  SET_SPECIAL_OFFER_CODE,
  SET_ACCOUNT_EXISTS,
  SET_SUBMITTED
} from 'store/actionTypes';

import requests from 'api/requests';

import getMeta from 'utils/getUserMeta';

import stalledClientsConstant from 'constants/stalledClientsConstant';

import {
  setStripeCustomerId,
  setStripeCustomerSecret,
  showCreditCardForm,
} from 'store/actions/creditCardForm';

// eslint-disable-next-line import/no-cycle
import {// createAccount,
} from 'store/actions/account';

const saveStalledClient = debounce(async (draftData) => {
  const metaData = await getMeta();

  requests.stalled.save({
    ...draftData,
    ...metaData,
  });
}, 2000);

export const setNewStalledStage = (value) => ({
  type: SET_STALLED_STAGE_ID,
  stalledStage: value,
});

export const setSubmitted = (value) => ({
  type: SET_SUBMITTED,
  submitted: value,
});

export const setDetailsFormFields = (fields) => (dispatch, getState) => {
  const {
    detailsForm: {
      clientEmail: {
        value: Email,
      },
      companyName: {
        value: CompanyName,
      },
      firstName: {
        value: FirstName,
      },
      lastName: {
        value: LastName,
      },
      phoneNumber: {
        value: PhoneNumber,
      },
      realtorId: {
        value: RealtorId,
      },
      stalledStage: stateStalledStage,
    },
  } = getState();

  const Token = sessionStorage.getItem('sessionToken');

  let stalledStage = stateStalledStage;

  if (Email && stalledStage !== 'creditCard' && stalledStage !== 'technicalError') {
    stalledStage = 'clientEmail';
    if (CompanyName && stalledStage !== 'creditCard' && stalledStage !== 'technicalError') {
      stalledStage = 'companyDetails';
    }
  }

  const {
    stalledStageName,
  } = stalledClientsConstant;

  const draftData = {
    CompanyName,
    Email: fields?.clientEmail?.value || Email,
    FirstName: fields?.firstName?.value || FirstName,
    LastName: fields?.lastName?.value || LastName,
    PhoneNumber: fields?.phoneNumber?.value || PhoneNumber,
    RealtorId,
    Token,
    stalledStageId: stalledStageName[stalledStage],
  };

  if (draftData.Email || draftData.FirstName || draftData.LastName || draftData.PhoneNumber) {
    saveStalledClient(draftData);
  }

  dispatch(setNewStalledStage(stalledStage));

  dispatch({
    type: DETAILS_FORM_FIELDS_CHANGE,
    payload: { ...fields },
  });
};

export const getCustomerSecret = (email) => async (dispatch) => {
  try {
    const stripeId = await requests.stripe.createStripeId(email);

    dispatch(setStripeCustomerId(stripeId));

    const customerSecret = await requests.stripe.createIntentAndGetSecret(stripeId);

    dispatch(setStripeCustomerSecret(customerSecret));
  } catch (error) {
    console.error(error);
  }
};

export const handleDetailsFormSubmit = () => async (dispatch, getState) => {
  const {
    detailsForm: {
      // specialOfferCode,
      clientEmail,
      companyName: {
        value: CompanyName,
      },
      firstName: {
        value: FirstName,
      },
      lastName: {
        value: LastName,
      },
      phoneNumber: {
        value: PhoneNumber,
      },
      realtorId: {
        value: RealtorId,
      },
    },
  } = getState();

  const Token = sessionStorage.getItem('sessionToken');

  const {
    stalledStageName,
  } = stalledClientsConstant;

  const draftData = {
    CompanyName,
    Email: clientEmail.value,
    FirstName,
    LastName,
    PhoneNumber,
    RealtorId,
    Token,
    stalledStageId: stalledStageName.creditCard,
  };

  // if (specialOfferCode?.makesFullPayment || !(+process.env.REACT_APP_REQUIRE_CREDIT_CARD)) {
  //   dispatch(setNewStalledStage('installation'));
  // }
  // else {
  dispatch(setNewStalledStage('creditCard'));
  // }

  await dispatch(getCustomerSecret(clientEmail.value));

  saveStalledClient(draftData);

  // if (specialOfferCode?.makesFullPayment || !(+process.env.REACT_APP_REQUIRE_CREDIT_CARD)) {
  //   dispatch(createAccount());
  // } else {
  dispatch(showCreditCardForm());
  // }
};

export const setSpecialOfferCode = (payload) => ({
  type: SET_SPECIAL_OFFER_CODE,
  payload,
});

export const checkIfAccountExists = (email) => async (dispatch) => {
  const result = await requests.account.checkIfAccountExists(email);

  dispatch({
    type: SET_ACCOUNT_EXISTS,
    payload: result,
  });
};

export default {
  setDetailsFormFields,
  setNewStalledStage,
  setSpecialOfferCode,
  checkIfAccountExists,
  getCustomerSecret,
  setSubmitted
};
