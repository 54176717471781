import React from 'react';
import PropTypes from 'prop-types';

import Flex from 'ui/Flex';
import FlexItem from 'ui/FlexItem';

import Typography from 'ui/Typography';
import Icon from 'ui/Icon';

const Salesperson = (props) => {
  const {
    salesperson,
  } = props;

  return (
    <Flex flexWrap="wrap">
      <FlexItem xs={12}>
        <Flex spacing={2} alignItems="center">
          <Icon
            color="primary"
            name="user"
            size="xl"
          />
          <div>
            <Typography
              style={{
                fontFamily: 'Poppins, Lato, Helvetica, Arial, sans-serif',
                fontSize: '18px',
              }}
            >
              {salesperson.first_name}
              {' '}
              {salesperson.last_name}
            </Typography>
            <Typography
              noMargin
              style={{
                fontFamily: 'Poppins, Lato, Helvetica, Arial, sans-serif',
                fontSize: '18px',
              }}
            >
              Sales Manager
            </Typography>
          </div>
        </Flex>
      </FlexItem>
      <FlexItem xs={12}>
        { salesperson.email && (
        <a href={`mailto:${salesperson.email}`}>
          <Typography
            style={{
              fontFamily: 'Poppins, Lato, Helvetica, Arial, sans-serif',
              textDecoration: 'underline',
              fontSize: '18px',
            }}
          >
            {salesperson.email}
          </Typography>
        </a>
        )}
        { salesperson.phone && (
        <a href={`tel:${salesperson.phone}`}>
          <Typography
            style={{
              fontFamily: 'Poppins, Lato, Helvetica, Arial, sans-serif',
              textDecoration: 'underline',
              fontSize: '18px',
            }}
          >
            {salesperson.phone}
          </Typography>
        </a>
        )}
      </FlexItem>
    </Flex>
  );
};

Salesperson.defaultProps = {

};

const {
  array,
  arrayOf,
  bool,
  element,
  func,
  instanceOf,
  number,
  object,
  objectOf,
  oneOf,
  oneOfType,
  shape,
  string,
} = PropTypes;

Salesperson.propTypes = {

};

export default Salesperson;
