import random from 'lodash/random';
import store from 'store';

let start = null;
let durationMs = null;
let accountLoadingComplete = false;

export default (callback, minTime = 25, maxTime = 35) => {
  const mockProgress = (timestamp) => {
    if (!durationMs) durationMs = random(minTime, maxTime) * 1000;

    if (!start) start = timestamp;

    const progress = timestamp - start;

    const {
      account: {
        isAccountBeingCreated,
      },
    } = store.getState();

    if (!isAccountBeingCreated && !accountLoadingComplete) {
      accountLoadingComplete = true;

      if (durationMs - progress > 2000) {
        durationMs = progress + 2000;
      }
    }

    if (progress >= durationMs) {
      if (!isAccountBeingCreated) {
        callback(100);
        return;
      }

      durationMs = progress + 100;
    }

    const progressPercentage = (progress / durationMs) * 100;

    if (progress < durationMs) {
      callback(Math.floor(progressPercentage));
      window.requestAnimationFrame(mockProgress);
    }
  };

  return window.requestAnimationFrame(mockProgress);
};
