/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

import htmlParser from 'react-html-parser';

import { Root } from './styledItems';

const tags = {
  h1: 'h1',
  heading: 'h1',

  h2: 'h2',
  title1: 'h2',

  h3: 'h3',
  title2: 'h3',
  button: 'span',

  h4: 'h4',
  title3: 'h4',

  h5: 'h5',
  title4: 'h5',
  caption: 'p',
  caption1: 'p',
  subtitle: 'p',
  subtitle1: 'p',

  h6: 'h6',
  caption2: 'p',
  subtitle2: 'p',

  body: 'p',
  body1: 'p',

  body2: 'p',

  link: 'span',
  cell: 'p',
  label: 'span',
  tag: 'span',
};

const getTag = (variant) => (variant ? tags[variant] : 'span');

const Typography = ({
  variant,
  tag,
  children,
  color,
  overrideColor,
  overrideFontFamily,
  overrideFontSize,
  align,
  noWrap,
  className,
  weight,
  textDecoration,
  fontStyle,
  noMargin,
  ellipsis,
  parseHtml,
  ...nodeProps
}) => (
  <Root
    as={tag || getTag(variant)}
    overrideColor={overrideColor}
    overrideFontFamily={overrideFontFamily}
    align={align}
    noWrap={noWrap}
    className={className}
    variant={variant}
    weight={weight}
    textDecoration={textDecoration}
    fontStyle={fontStyle}
    noMargin={noMargin}
    fontSize={overrideFontSize}
    ellipsis={ellipsis}
    {...nodeProps}
  >
    {parseHtml ? htmlParser(children) : children}
  </Root>
);

Typography.defaultProps = {
  variant: 'body',
  children: null,
  tag: '',
  color: '',
  overrideColor: '',
  overrideFontFamily: '',
  noWrap: false,
  className: '',
  align: null,
  fontStyle: null,
  weight: null,
  textDecoration: null,
  noMargin: false,
  overrideFontSize: null,
  ellipsis: false,
  parseHtml: false,
};

const {
  string,
  number,
  oneOf,
  oneOfType,
  element,
  array,
  bool,
} = PropTypes;

Typography.propTypes = {
  children: oneOfType([string, number, element, array]),
  /**
   * font style variant
   */
  variant: oneOf([
    // Symplete semantic variants
    'heading',

    'title1',

    'title2',
    'button',

    'title3',

    'caption',
    'caption1',
    'subtitle',
    'subtitle1',

    'caption2',
    'subtitle2',

    'body',
    'body1',

    'body2',

    // Legacy semantic variants
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'link',
    'cell',
    'label',
    'tag',
  ]),
  tag: string,
  className: string,
  color: oneOf([
    // Symplete semantic colors
    'focus',
    'success',
    'warning',
    'alert',
    'disabled',
    'main-text',
    'descriptor-text',

    // Legacy semantic colors
    'primary',
    'additional',
    'ghost',
    'link',
    'error',
    'highlight',
    'default',
    'attention',
    'hover',
    'contrast',
  ]),
  overrideColor: string,
  overrideFontFamily: string,
  overrideFontSize: string,
  noWrap: bool,
  align: oneOf([
    'center',
    'left',
    'right',
    'justify',
  ]),
  fontStyle: oneOf([
    'normal',
    'italic',
    'oblique',
    'inherit',
    'initial',
    'unset',
  ]),
  weight: oneOf([
    'normal',
    'bold',
    'inherit',
    'initial',
    'unset',
    100,
    200,
    300,
    400,
    500,
    600,
    700,
  ]),
  /** All acceptable CSS properties */
  textDecoration: string,
  /** reset default margins */
  noMargin: bool,
  ellipsis: bool,
  parseHtml: bool,
};

export default Typography;
