import React, {
  useState,
  useEffect,
} from 'react';

import PropTypes from 'prop-types';
import { Layout } from 'antd';

import routes from 'constants/routes';
import { useDispatch } from 'react-redux';
import { createAccount } from 'store/actions/account';
import Header from './Header';

import YourDetailsSection from './YourDetailsSection';
import SubscriptionChoiceSection from './SubscriptionChoiceSection';
// import PaymentSection from './PaymentSection';
import InstallationProgressSection from './InstallationProgressSection';

import styles from './styles.module.scss';

const { Content } = Layout;

const SignupV3 = (props) => {
  const {
    isCreditCardFormVisible,
    isAccountBeingCreated,

    detailsDataComplete,
    subscriptionSelected,

    history,
  } = props;
  const dispatch = useDispatch();
  const [currentStep, setCurrentStep] = useState('details');

  useEffect(() => {
    if (subscriptionSelected && detailsDataComplete) {
      setCurrentStep('installation');
      dispatch(createAccount());
    }
  }, [subscriptionSelected, detailsDataComplete]);

  return (
    <>
      <div className={styles.mainWrapper}>
        <Layout className={styles.main}>
          {
            currentStep !== 'details' && (
              <Header />
            )
          }
          <Content className={styles.content}>
            {
              currentStep === 'details' && (
                <YourDetailsSection />
              )
            }
            {/* {
              currentStep === 'subscriptionChoice' && (
                <SubscriptionChoiceSection />
              )
            } */}
            {/* {
              currentStep === 'payment' && (
                <PaymentSection />
              )
            } */}
            {
              currentStep === 'installation' && (
                <InstallationProgressSection />
              )
            }
          </Content>
        </Layout>
      </div>
    </>
  );
};

SignupV3.defaultProps = {
  isCreditCardFormVisible: false,
  isAccountBeingCreated: false,
  detailsDataComplete: false,
  subscriptionSelected: false,
};

const {
  bool,
  shape,
} = PropTypes;

SignupV3.propTypes = {
  isCreditCardFormVisible: bool,
  isAccountBeingCreated: bool,
  detailsDataComplete: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  subscriptionSelected: bool,
  history: shape().isRequired,
};

export default SignupV3;
