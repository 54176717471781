import styled from 'styled-components';

import NakedButton from 'ui/NakedButton';

const getRootPadding = (props) => {
  if (!props.hasText) {
    return 0;
  }

  return '10px 16px';
};

const getBackground = (type) => (props) => {
  if (type === 'regular') {
    if (props.variant === 'primary') {
      return props.theme?.palette?.focus || '#367BED';
    }
    if (props.variant === 'secondary') {
      return props.theme?.palette?.background || '#ffffff';
    }
    if (props.variant === 'tertiary') {
      // return props.theme?.palette?.background;
      return 'transparent';
    }
  }

  if (type === 'hover') {
    if (props.variant === 'primary') {
      return props.theme?.palette?.['focus-inversed'];
    }
    if (props.variant === 'secondary') {
      return props.theme?.palette?.['background-hover'];
    }
    if (props.variant === 'tertiary') {
      return 'transparent';
    }
  }

  if (type === 'disabled') {
    if (props.variant === 'primary') {
      return props.theme?.palette?.disabled;
    }
    if (props.variant === 'secondary') {
      return props.theme?.palette?.disabled;
    }
    if (props.variant === 'tertiary') {
      return 'transparent';
    }
  }

  return 'blue';
};

const getShadow = (props) => {
  if (props.variant === 'secondary') {
    if (!props.disabled) {
      return props.theme?.shadows?.button;
    }
  }

  return 'none';
};

const getWidth = (props) => {
  if (props.fullWidth) return '100%';

  if (!props.hasText) return '44px';

  return 'auto';
};

const getHeight = (props) => {
  if (!props.hasText) return '44px';

  // return 'auto';
  return '44px';
};

const getJustify = (props) => {
  if (!props.hasText || !props.icon) return 'center';
  return 'space-between';
};

const getIconOffset = (props) => {
  if (props.iconOffset || props.iconOffset === 0) return props.iconOffset;
  return props.hasText ? 8 : 0;
};

export const Root = styled(NakedButton)`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: ${(getJustify)};
  width: ${getWidth};
  height: ${getHeight};
  box-shadow: ${(props) => getShadow(props)};
  padding: ${(props) => getRootPadding(props)};
  border-radius: 10px;
  background: ${(props) => getBackground('regular')(props)};
  &:hover {
    background: ${(props) => getBackground('hover')(props)};
  }
  &:disabled {
    background: ${(props) => getBackground('disabled')(props)};
  }
  &:active, &.activated {
    box-shadow: none;
    transform: scale(0.99);
    transform-origin: center;
    transition-duration: 0.3s;
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  margin-right: ${(props) => (getIconOffset(props))}px;
  justify-content: center;
  align-items: center;
`;

export default {
  Root,
  IconWrapper,
};
