import apiConnector, { endpoints } from 'api';

const accept = async (token) => {
  console.log('start...');

  const response = await apiConnector.global.post(endpoints.global.external.offers, null, token);

  console.log('');
  console.log('');
  console.log('response:', response);
  console.log('');
  console.log('');

  return response.data;
};

export default accept;
