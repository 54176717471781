// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles_button__1rn1k {\n  width: 100%;\n  margin-top: 15px;\n  height: 50px;\n  border-radius: 10px;\n  border-color: #3B2A80;\n  background: #3B2A80;\n  text-transform: uppercase;\n  font-weight: 600; }\n", ""]);
// Exports
exports.locals = {
	"button": "styles_button__1rn1k"
};
module.exports = exports;
