export const BILLING_PERIOD_TYPES = {
  monthly: 1,
  yearly: 2,
};

export const MONTHLY_DEFAULT_PRICE = 75;

export default {
  BILLING_PERIOD_TYPES,
  MONTHLY_DEFAULT_PRICE,
};
