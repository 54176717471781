// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles_mainWrapper__1fHel {\n  display: flex;\n  justify-content: center;\n  background-repeat: no-repeat;\n  background-size: cover;\n  background-position: center;\n  padding-bottom: 40px; }\n  @media (max-width: 360px) {\n    .styles_mainWrapper__1fHel {\n      overflow-x: hidden; } }\n\n.styles_main__xsa3G {\n  background: none;\n  display: flex;\n  max-width: 1366px; }\n\n.styles_content__StPl- {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-evenly;\n  flex-wrap: wrap;\n  align-items: flex-start;\n  margin-top: 20px;\n  margin-bottom: 30px;\n  padding: 0 5px; }\n\n.styles_mobileHeader__rCnpH {\n  display: none; }\n\n@media (max-width: 425px) {\n  .styles_outstandingBanner__3vixL {\n    display: none; }\n  .styles_mainWrapper__1fHel {\n    padding-bottom: 0; }\n  .styles_mobileHeader__rCnpH {\n    margin-top: 15px;\n    display: block;\n    font-size: 33px;\n    font-family: HelveticaNeue;\n    color: #445383;\n    text-align: center;\n    font-weight: bold; }\n  .styles_mobileHeader__rCnpH p {\n    margin-bottom: 0; } }\n", ""]);
// Exports
exports.locals = {
	"mainWrapper": "styles_mainWrapper__1fHel",
	"main": "styles_main__xsa3G",
	"content": "styles_content__StPl-",
	"mobileHeader": "styles_mobileHeader__rCnpH",
	"outstandingBanner": "styles_outstandingBanner__3vixL"
};
module.exports = exports;
