import React from 'react';
import PropTypes from 'prop-types';

import {
  Row,
  Col,
  Progress,
} from 'antd';

import launchImage from 'assets/launch-image.png';

import {
  Title,
  Subtitle,
  InfoText,
  Illustration,
} from './styledItems';

const InstallationProgressSection = (props) => {
  const {
    percent,
    clientEmail,
  } = props;

  return (
    <Row type="flex" justify="center" gutter={[0, 32]}>
      <Col span={24}>
        <Title>Getting ready for launch.</Title>
      </Col>
      <Col span={20}>
        <Subtitle>
          {`Don’t leave this screen! Your account will be ready soon. We will also email your
          login details to ${clientEmail}`}
        </Subtitle>
      </Col>
      <Col span={20}>
        <Progress
          percent={percent}
          showInfo={false}
        />
      </Col>
      <Col>
        <Illustration src={launchImage} width="320" alt="Rocket" />
      </Col>
      <Col span={24}>
        <InfoText>
          For free trial users, please give us a call and we can help you set up.
          For paying clients, we will be in touch within 2 hours.
        </InfoText>
      </Col>
    </Row>
  );
};

const {
  string,
  number,
} = PropTypes;

InstallationProgressSection.propTypes = {
  percent: number.isRequired,
  clientEmail: string.isRequired,
};

export default InstallationProgressSection;
