import React, {
  useMemo,
} from 'react';

import PropTypes from 'prop-types';

import Typography from 'ui/Typography';
import Flex from 'ui/Flex';
import FlexItem from 'ui/FlexItem';
import Button from 'ui/Button';

import PricingSimple from './PricingSimple';
import PricingAdvanced from './PricingAdvanced';

const pricingComponents = {
  1: PricingSimple,
  2: PricingAdvanced,
};

const OfferContent = (props) => {
  const {
    offer,
    acceptOffer,
  } = props;

  const pricing = useMemo(() => {
    if (!offer?.pricing_type) return null;

    const PricingComponent = pricingComponents[offer?.pricing_type];

    return (
      <PricingComponent offer={offer} />
    );
  }, [
    offer?.pricing_type,
  ]);

  return (
    <Flex flexWrap="wrap">
      <FlexItem fullWidth>
        <Typography
          variant="title1"
          tag="h1"
          align="left"
        >
          New client sign up
        </Typography>
      </FlexItem>

      <FlexItem xs={16}>
        <Flex spacing={2} flexWrap="wrap">
          <FlexItem fullWidth>
            <Typography 
              variant="title2"
              tag="h2"
              color="focus"
            >
              Client Details
            </Typography>
            <Flex spacing={2} flexWrap="wrap">
              <FlexItem xs={12}>
                {
                  (offer?.client_obj?.firstName || offer?.client_obj?.lastName) && (
                    <Typography
                      variant="title1"
                      tag="h3"
                    >
                      { `${offer?.client_obj?.firstName || ''} ${offer?.client_obj?.lastName}` || ''}
                    </Typography>
                  )
                }
                {
                  offer?.client_obj?.email && (
                    <Typography>
                      { `${offer?.client_obj?.email}` }
                    </Typography>
                  )
                }
                {
                  offer?.client_obj?.phone && (
                    <Typography>
                      { `${offer?.client_obj?.phone}` }
                    </Typography>
                  )
                }
              </FlexItem>
              <FlexItem xs={12}>
                {
                  offer?.client_obj?.companyName && (
                    <p>
                      <Typography
                        weight="700"
                        tag="span"
                      >
                        Company Name:&nbsp;
                      </Typography>
                      <Typography
                        tag="span"
                      >
                        { `${offer?.client_obj?.companyName}` }
                      </Typography>
                    </p>
                  )
                }
                {
                  offer?.client_obj?.licenseNumber && (
                    <p>
                      <Typography
                        weight="700"
                        tag="span"
                      >
                        License #:&nbsp;
                      </Typography>
                      <Typography
                        tag="span"
                      >
                        { `${offer?.client_obj?.licenseNumber}` }
                      </Typography>
                    </p>
                  )
                }
                {
                  offer?.client_obj?.officeAddress && (
                    <p>
                      <Typography
                        weight="700"
                        tag="span"
                      >
                        Office Address:&nbsp;
                      </Typography>
                      <Typography
                        tag="span"
                      >
                        { `${offer?.client_obj?.officeAddress}` }
                      </Typography>
                    </p>
                  )
                }
              </FlexItem>
            </Flex>
          </FlexItem>

          <FlexItem xs={12}>
            <Typography
              variant="title2"
              tag="h2"
              color="focus"
            >
              License Type
            </Typography>
            <Typography>
              Standard
            </Typography>
          </FlexItem>

          <FlexItem xs={12}>
            <Typography
              variant="title2"
              tag="h2"
              color="focus"
            >
              Trial Period
            </Typography>
            <Typography>
              {offer?.trial_period_days}
              {' '}
              days
            </Typography>
          </FlexItem>

          <FlexItem fullWidth>
            <Typography
              variant="title2"
              tag="h2"
              color="focus"
            >
              Pricing Terms
            </Typography>

            { pricing }

          </FlexItem>
        </Flex>
      </FlexItem>

      <FlexItem xs={8}>
        <Flex spacing={1} flexWrap="wrap">
          <Typography
            variant="title2"
            tag="h2"
            color="focus"
          >
            Added Extras
          </Typography>
          {
            offer?.extras && (
              offer.extras.map((item) => (
                <Flex fullWidth justifyContent="space-between">
                  <Typography
                    weight="700"
                    tag="span"
                    style={{
                      textTransform: 'capitalize',
                    }}
                  >
                    { item[2] }
                    :&nbsp;
                  </Typography>
                  <Typography
                    tag="span"
                  >
                    $
                    { item[1] }
                  </Typography>
                </Flex>
              ))
            )
          }
        </Flex>

        <Flex spacing={1} flexWrap="wrap" style={{ marginTop: 36 }}>
          <Flex fullWidth justifyContent="space-between">
            <Typography
              weight="700"
              tag="span"
              style={{
                textTransform: 'capitalize',
              }}
            >
              MSRP
            </Typography>
            <Typography
              tag="span"
            >
              $
              {offer?.MSRP}
            </Typography>
          </Flex>

          {
            offer?.discount[0] > 0 && (
              <Flex fullWidth justifyContent="space-between">
                <Typography
                  weight="700"
                  tag="span"
                  color="success"
                  style={{
                    textTransform: 'capitalize',
                  }}
                >
                  First Year Discount Total
                </Typography>
                <Typography
                  tag="span"
                  color="success"
                >
                  {offer?.discount[1]}
                  % or $
                  {offer?.discount[0]}
                </Typography>
              </Flex>
            )
          }
          <Flex fullWidth justifyContent="space-between">
            <Typography
              weight="700"
              tag="span"
              style={{
                textTransform: 'capitalize',
              }}
            >
              Due Today
            </Typography>
            <Typography
              tag="span"
            >
              $
              {offer?.dueToday}
            </Typography>
          </Flex>

          <Button onClick={acceptOffer}>Accept offer and create account</Button>
        </Flex>
      </FlexItem>
    </Flex>
  );
};

OfferContent.defaultProps = {

};

const {
  array,
  arrayOf,
  bool,
  element,
  func,
  instanceOf,
  number,
  object,
  objectOf,
  oneOf,
  oneOfType,
  shape,
  string,
} = PropTypes;

OfferContent.propTypes = {

};

export default OfferContent;
