import { isPossibleNumber } from 'libphonenumber-js';

export const getFirstNameError = (value) => {
    let error = null;
    if (!value) error = 'First Name is required';
    else if (!/\w*[a-zA-Z0-9]\w*/.test(value)) error = 'At least 1 character is required!';
    return error;
};

export const getLastNameError = (value) => {
    let error = null;
    if (!value) error = 'Last Name is required';
    else if (!/\w*[a-zA-Z0-9]\w*/.test(value)) error = 'At least 1 character is required!';
    return error;
};

export const getEmailError = (value) => {
    let error = null;
    if (!value) error = 'Email is required';
    else if (!/^[^@]+@[^@]+\.[^@]+$/.test(value)) error = 'The input is not a valid E-mail!';
    return error;
};

export const getPhoneError = (value, country = 'US') => {
    let error = null;
    if (!value) error = 'Phone number is required';
    else if (!isPossibleNumber(`${value}`, country)) error = 'The phone number is not valid!';
    return error;
};

export const getErrorWarning = (errors) => {
    const errorValues = Object.values(errors);
    const nonEmptyErrors = errorValues.filter(value => value !== null && value !== undefined);

    if (nonEmptyErrors.length === 0) {
        return false;
    } else {
        return 'Please complete the required fields';
    }
}

export default {
    getFirstNameError,
    getLastNameError,
    getEmailError,
    getPhoneError,
    getErrorWarning
};
