import styled from 'styled-components';

const columnsCount = 24;

const getWidth = (props) => {
  const {
    xs,
    fullWidth,
    gap = 0,
  } = props;

  if (fullWidth) return '100%';
  if (!xs) return 'auto';

  const percentage = xs * 100 / columnsCount;

  return `calc(${percentage}% - ${gap / 2}px)`;
};

export const Root = styled.div`
  width: ${getWidth};
  align-self: ${(props) => props.alignSelf};
  padding: ${(props) => props.padding || 0};
`;

export default {
  Root,
};
