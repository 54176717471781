import React from 'react';
import PropTypes from 'prop-types';

import {
  Root,
} from './styledItems';

const FlexItem = (props) => {
  const {
    children,
    xs,
    fullWidth,
    alignSelf,
    padding,
    gap,
    style,
  } = props;

  return (
    <Root
      xs={xs}
      fullWidth={fullWidth}
      alignSelf={alignSelf}
      padding={padding}
      gap={gap}
      style={style}
    >
      {children}
    </Root>
  );
};

FlexItem.defaultProps = {
  xs: null,
  fullWidth: false,
  alignSelf: 'initial',
  gap: 0,
  style: {},
};

const {
  bool,
  number,
  string,
} = PropTypes;

FlexItem.propTypes = {
  xs: number,
  fullWidth: bool,
  alignSelf: string,
  padding: string,
  gap: number,
};

export default FlexItem;
