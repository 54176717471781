import requests from 'api/requests';
import routes from 'constants/routes';

import {
  SET_IS_ACCOUNT_BEING_CREATED_YES,
  SET_IS_ACCOUNT_BEING_CREATED_NO,
  SET_CREATION_PROGRESS,
} from 'store/actionTypes';

import getMeta from 'utils/getUserMeta';

import stalledClientsConstant from 'constants/stalledClientsConstant';
// eslint-disable-next-line import/no-cycle
import { setNewStalledStage } from 'store/actions/detailsForm';

import mockProgress from 'utils/mockProgress';

export const setIsAccountBeingCreatedYes = () => ({
  type: SET_IS_ACCOUNT_BEING_CREATED_YES,
});

export const setIsAccountBeingCreatedNo = () => ({
  type: SET_IS_ACCOUNT_BEING_CREATED_NO,
});

export const setAccountCreationProgress = (progress) => ({
  type: SET_CREATION_PROGRESS,
  payload: progress,
});

export const createAccount = () => async (dispatch, getState) => {
  const {
    detailsForm: {
      clientEmail: {
        value: clientEmail,
      },
      companyName: {
        value: companyName,
      },
      firstName: {
        value: firstName,
      },
      lastName: {
        value: lastName,
      },
      phoneNumber: {
        value: phoneNumber,
      },
      discountCode: {
        value: discountCode,
      },
      realtorId: {
        value: realtorId,
      },
      subscriptionType: {
        value: subscriptionType,
      },
    },
    creditCardForm: {
      stripeCustomerId,
      billingPeriodType,
      confirmedNameOnCard,
    },
  } = getState();

  const data = {
    clientEmail,
    firstName,
    lastName,
    phoneNumber,
    companyName,
    realtorId,
    stripeCustomerId,
    isEarlyAccess: process.env.REACT_APP_IS_EARLY_ACCESS,
    billingPeriodType: subscriptionType,
    ...discountCode ? { specialOfferCode: discountCode } : {},
    nameOnCreditCard: confirmedNameOnCard,
    isTrialPeriod: subscriptionType === 0,
  };

  dispatch(setIsAccountBeingCreatedYes());

  mockProgress((progress) => {
    dispatch(setAccountCreationProgress(progress));
  });

  try {
    const response = await requests.account.create(data);

    const {
      login,
      password,
    } = response;

    try {
      const Token = sessionStorage.getItem('sessionToken');

      const draftData = {
        CompanyName: companyName,
        Email: clientEmail,
        FirstName: firstName,
        LastName: lastName,
        PhoneNumber: phoneNumber,
        Token,
        Resolved: 1,
      };

      requests.stalled.save(draftData);
    } catch (error) {
      console.error(error);
    }

    const appUrl = `${process.env.REACT_APP_SYMPLETE_CLIENT_APP_URL}/login-new?action=new_client&user=${encodeURIComponent(login)}&tempPass=${encodeURIComponent(password)}`

    window.location.replace(appUrl);
  } catch (error) {
    const {
      stalledStageName,
    } = stalledClientsConstant;

    const metaData = await getMeta();

    const Token = sessionStorage.getItem('sessionToken');

    const errorDraftData = {
      CompanyName: companyName,
      Email: clientEmail,
      FirstName: firstName,
      LastName: lastName,
      PhoneNumber: phoneNumber,
      Token,
      stalledStageId: stalledStageName.technicalError,
      ...metaData,
    };

    dispatch(setNewStalledStage('technicalError'));

    requests.stalled.save(errorDraftData);

    dispatch(setIsAccountBeingCreatedNo());
    window.location.replace(routes.errorOnSignup);
  }
};
