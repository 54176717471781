import {
  SHOW_CREDIT_CARD_FORM,
  HIDE_CREDIT_CARD_FORM,
  SET_STRIPE_CUSTOMER_ID,
  SET_STRIPE_CUSTOMER_SECRET,
  SET_CONFIRMED_NAME_ON_CARD,
} from 'store/actionTypes';

export const showCreditCardForm = () => ({
  type: SHOW_CREDIT_CARD_FORM,
});

export const hideCreditCardForm = () => ({
  type: HIDE_CREDIT_CARD_FORM,
});

export const setStripeCustomerId = (id) => ({
  type: SET_STRIPE_CUSTOMER_ID,
  payload: id,
});

export const setStripeCustomerSecret = (secret) => ({
  type: SET_STRIPE_CUSTOMER_SECRET,
  payload: secret,
});

export const setConfirmedNameOnCard = (name) => ({
  type: SET_CONFIRMED_NAME_ON_CARD,
  payload: name,
});

export default {
  showCreditCardForm,
  hideCreditCardForm,
  setStripeCustomerSecret,
  setConfirmedNameOnCard,
};
