export const USER_ORDER_FIELDS_CHANGE = 'USER_ORDER_FIELDS_CHANGE';

export const USER_POST_DRAFT_REQUEST = 'USER_POST_DRAFT_REQUEST';
export const USER_POST_DRAFT_SUCCESS = 'USER_POST_DRAFT_SUCCESS';
export const USER_POST_DRAFT_FAILURE = 'USER_POST_DRAFT_FAILURE';

export const USER_PURCHASE_REQUEST = 'USER_PURCHASE_REQUEST';
export const USER_PURCHASE_SUCCESS = 'USER_PURCHASE_SUCCESS';
export const USER_PURCHASE_FAILURE = 'USER_PURCHASE_FAILURE';

export const CUSTOMER_CHECKBOX_CHECK = 'CUSTOMER_CHECKBOX_CHECK';

export const CUSTOMER_SET_MIN_COUNT = 'CUSTOMER_SET_MIN_COUNT';

export const CHECKBOX_TC_ERROR = 'CHECKBOX_TC_ERROR';

export const CUSTOMER_SET_IS_CUSTOMER = 'CUSTOMER_SET_IS_CUSTOMER';

export const FETCH_LICENSES_REQUEST = 'FETCH_LICENSES_REQUEST';
export const FETCH_LICENSES_SUCCESS = 'FETCH_LICENSES_SUCCESS';
export const FETCH_LICENSES_FAILURE = 'FETCH_LICENSES_FAILURE';

export const FETCH_FREE_LICENSES_SUCCESS = 'FETCH_FREE_LICENSES_SUCCESS';
export const FETCH_FREE_LICENSES_FAILURE = 'FETCH_FREE_LICENSES_FAILURE';

export const FETCH_TEXT_SUCCESS = 'FETCH_TEXT_SUCCESS';
export const FETCH_TEXT_FAILURE = 'FETCH_TEXT_FAILURE';

// Client source
export const SET_CLIENT_SOURCE = 'SET_CLIENT_SOURCE';

// Landing page version
export const SET_LANDING_VERSION = 'SET_LANDING_VERSION';

// Send Me More
export const SET_SEND_ME_MORE = 'SET_SEND_ME_MORE';

// Details form
export const DETAILS_FORM_FIELDS_CHANGE = 'DETAILS_FORM_FIELDS_CHANGE';
export const SET_STALLED_STAGE_ID = 'SET_STALLED_STAGE_ID';
export const SET_SUBMITTED = 'SET_SUBMITTED';
export const SET_SPECIAL_OFFER_CODE = 'SET_SPECIAL_OFFER_CODE';
export const SET_ACCOUNT_EXISTS = 'SET_ACCOUNT_EXISTS';

// Credit card form
export const SHOW_CREDIT_CARD_FORM = 'SHOW_CREDIT_CARD_FORM';
export const HIDE_CREDIT_CARD_FORM = 'HIDE_CREDIT_CARD_FORM';
export const SET_STRIPE_CUSTOMER_ID = 'SET_STRIPE_CUSTOMER_ID';
export const SET_STRIPE_CUSTOMER_SECRET = 'SET_STRIPE_CUSTOMER_SECRET';
export const SET_CONFIRMED_NAME_ON_CARD = 'SET_CONFIRMED_NAME_ON_CARD';

// Account
export const SET_IS_ACCOUNT_BEING_CREATED_YES = 'SET_IS_ACCOUNT_BEING_CREATED_YES';
export const SET_IS_ACCOUNT_BEING_CREATED_NO = 'SET_IS_ACCOUNT_BEING_CREATED_NO';
export const SET_CREATION_PROGRESS = 'SET_CREATION_PROGRESS';
