import { connect } from 'react-redux';
import SignupV3 from './SignupV3';

const mapStateToProps = (state) => {
  const {
    detailsForm: {
      clientEmail,
      firstName,
      lastName,
      phoneNumber,
      subscriptionType,
      submitted,
    },
    creditCardForm: {
      isVisible: isCreditCardFormVisible,
    },
    account: {
      isAccountBeingCreated,
    },
  } = state;

  const detailsDataComplete = clientEmail.value && firstName.value
    && lastName.value && phoneNumber.value && submitted;

  const subscriptionSelected = !!subscriptionType.value;

  return {
    isCreditCardFormVisible,
    isAccountBeingCreated,
    detailsDataComplete,
    subscriptionSelected,
  };
};

export default connect(
  mapStateToProps,
)(
  SignupV3,
);
