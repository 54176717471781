export default {
  landingPage3: '/lp3',
  signUp: '/signup',
  companyDetails: '/company-details',
  payment: '/payment',
  paymentDeal: '/payment/:deal/:uuid',
  confirmation: '/confirmation',
  startUsing: '/start-using',
  errorOnSignup: '/creation-in-progress',
  offers: '/offers',
  creationProgress: '/creating-account',
};
