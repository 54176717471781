import React from 'react';
import PropTypes from 'prop-types';

import Typography from 'ui/Typography';

const paymentPeriods = {
  1: 'Monthly',
  2: 'Quarterly',
  3: 'Yearly',
  4: 'For two years',
};

const PricingSimple = (props) => {
  const {
    offer,
  } = props;

  return (
    <div>
      <Typography>{ paymentPeriods[offer?.simple_payment_period] || 'N/A' }</Typography>
      <Typography>
        $
        {offer?.simple_monthly_price}
        /month will be the fixed price per license
      </Typography>
    </div>
  );
};

PricingSimple.defaultProps = {

};

const {
  array,
  arrayOf,
  bool,
  element,
  func,
  instanceOf,
  number,
  object,
  objectOf,
  oneOf,
  oneOfType,
  shape,
  string,
} = PropTypes;

PricingSimple.propTypes = {

};

export default PricingSimple;
