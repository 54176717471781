import React from 'react';
import PropTypes from 'prop-types';

import {
  Layout,
} from 'antd';

import HeaderSimple from 'pages/common/HeaderSimple';
import StepFooter from 'pages/common/StepFooter';
import styles from './styles.module.scss';

const { Content } = Layout;

const ErrorOnSignup = (props) => {
  const {
    firstName,
  } = props;

  const content = (
    <div className={styles.warningBox}>
      <p className={styles.greeting}>
        We are in the process of setting up your system.
      </p>
      <p className={styles.greeting}>
        We will email your login details within the next 8 business hours.
      </p>
    </div>
  );


  return (
    <div className={styles.page}>
      <Layout className={styles.main}>
        <HeaderSimple />
        <Content className={styles.content}>
          <p className={styles.greeting}>
            Welcome to Symplete and thank you for signing up,
            {' '}
            {firstName}
            !
          </p>
          {content}
        </Content>
        <StepFooter />
      </Layout>
    </div>
  );
};


ErrorOnSignup.defaultProps = {
  firstName: null,
};

const {
  string,
} = PropTypes;

ErrorOnSignup.propTypes = {
  firstName: string,
};

export default ErrorOnSignup;
