import React from 'react';

import styles from './styles.module.scss';

const StepFooter = () => (
  <p className={styles.stepFooterText}>
    Questions? We would love to hear from you:
    {' '}
    <a href="tel:8053092548">(805) 309-2548</a>
  </p>
);

export default StepFooter;
