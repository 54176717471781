import styled from 'styled-components';

export const Title = styled.p`
  font-size: 2.25rem;
  font-family: GTEestiProDisplay, Roboto, Arial, sans-serif;
  font-weight: medium;
  color: #fff;
  text-align: center;
`;

export const OptionWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 420px;
  height: 100%;
  max-width: 100%;
  overflow: hidden;
  padding: 35px 25px 15px 25px;
  border-radius: 10px;
  background-color: #f5f5f7;
`;

export const OptionTitle = styled.p`
  font-size: 2.25rem;
  font-family: GTEestiProDisplay, Roboto, Arial, sans-serif;
  font-weight: bold;
  color: #141637;
  margin-bottom: 20px;
  text-align: center;
`;

export const OptionContentWrapper = styled.div`
  border-radius: 10px;
  border: solid 1px #d3d8df;
  background-color: #fff;
  padding: 20px 20px 10px 20px;
  margin-bottom: 10px;
`;

export const OptionText = styled.p`
  font-size: 19px;
  font-family: GTEestiProDisplay, Roboto, Arial, sans-serif;
  font-weight: normal;
  color: #141736;
  margin-top: 18px;
`;

export const UList = styled.ul`
  font-family: GTEestiProDisplay, Roboto, Arial, sans-serif;
  font-weight: normal;
  color: #141736;
  margin: 10px 0 0;
  padding-left: 0;

  li {
    font-size: 36px;
    list-style-type: none;
    display: flex;
    align-items: center;
  }
  li:before {
    content: "✓";
    padding-right: 10px;
    color: #367bed;
  }

  span,
  strong {
    font-size: 19px;
  }
`;

export const DiscountStatementWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  background-color: #B0D7CD;
  /* padding: 3px 12px; */
  overflow: hidden;
  justify-content: space-between;
`;

export const DiscountText = styled.p`
  text-align: center;
  flex: 1;
  font-size: 18px;
  font-weight: 500;
  color: #000;
  margin: 0;
  padding: 3px 6px;
  text-transform: uppercase;
`;

export const LabelText = styled.p`
  flex: 1;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  color: #fff;
  margin: 0;
  background-color: #DD6160;
  padding: 3px 6px;
`;

export const Price = styled.span`
  color: #367bed;
  font-size: 40px;
  font-weight: 500;
`

export const PricePeriod = styled.span`
  color: rgba(20, 23, 54, 0.6);
  font-size: 17px;
`

export const Billed = styled.p`
  text-align: center;
  color: #141736;
  font-size: 17px;
`

export const Save = styled.div`
  border-radius: 10px;
  background-color: #141637;
  padding: 18px;
  h2 {
    font-size: 30px;
    line-height: 30px;
    margin-bottom: 15px;
    color: #2ecc71;
    text-align: center;
    font-weight: bold;
  }
`

export const CancelationStatement = styled.p`
  font-size: 12px;
  font-family: GTEestiProDisplay, Roboto, Arial, sans-serif;
  font-weight: normal;
  text-align: center;
  color: #141736;
  margin-top: 10px;
  margin-bottom: 0;
`;
