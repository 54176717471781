import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { Row, Col, Button } from 'antd';

import {
  // Title,
  OptionWrapper,
  OptionTitle,
  // OptionContentWrapper,
  // OptionText,
  UList,
  DiscountStatementWrapper,
  DiscountText,
  CancelationStatement,
  LabelText,
  Price,
  PricePeriod,
  // Billed,
  Save,
} from './styledItems';

import styles from './styles.module.scss';

const SubscriptionChoiceSection = (props) => {
  const {
    setDetailsFormFields,
    // createAccount,
  } = props;

  const selectSubscription = useCallback(
    (subscriptionType) => () => {
      setDetailsFormFields({
        subscriptionType: { value: subscriptionType },
      });
    },
    [],
  );

  return (
    <Row type="flex" justify="center" align="stretch" gutter={[18, 0]}>
      {/* <Col span={24}>
        <Title>
          Select an option below
        </Title>
      </Col> */}
      <Col span={24}>
        <Save>
          <Row type="flex" justify="center" align="stretch" gutter={[18, 0]}>
            <Col span={12}>
              <OptionWrapper>
                <div>
                  <OptionTitle>Monthly</OptionTitle>

                  <p style={{ textAlign: 'center' }}>
                    <Price>$145</Price>
                    <PricePeriod>/month</PricePeriod>
                  </p>

                  {/* <Billed> */}
                  {/*  Billed monthly */}
                  {/* </Billed> */}

                  <UList>
                    <li>
                      <strong>10 listing leads</strong>
                    </li>
                    <li>
                      <span>Onboarding</span>
                    </li>
                    <li>
                      <span>Training call</span>
                    </li>
                  </UList>
                </div>

                <div>
                  <CancelationStatement>
                    No long-term commitment
                  </CancelationStatement>
                  <Button
                    type="primary"
                    className={styles.button}
                    onClick={selectSubscription('1')}
                  >
                    Get Started
                  </Button>
                </div>
              </OptionWrapper>
            </Col>

            <Col span={12}>
              <OptionWrapper>
                <DiscountStatementWrapper>
                  <DiscountText>Save $290</DiscountText>
                  <LabelText>MOST POPULAR</LabelText>
                </DiscountStatementWrapper>
                <div>
                  <OptionTitle>1 year</OptionTitle>

                  <p style={{ textAlign: 'center' }}>
                    <Price>$1450</Price>
                    <PricePeriod>/year</PricePeriod>
                  </p>

                  <UList>
                    <li>
                      <strong>20 listing leads</strong>
                    </li>
                    <li>
                      <span>Setup & Onboarding</span>
                    </li>
                    <li>
                      <span>Assisted data import</span>
                    </li>
                  </UList>
                </div>

                <div>
                  <Button
                    type="primary"
                    className={styles.button}
                    onClick={selectSubscription('2')}
                  >
                    Get Started
                  </Button>
                </div>
              </OptionWrapper>
            </Col>

            {/* <Col span={8}> */}
            {/*  <OptionWrapper> */}
            {/*    <DiscountStatementWrapper> */}
            {/*      <DiscountText>Save $672</DiscountText> */}
            {/*    </DiscountStatementWrapper> */}
            {/*    <div> */}
            {/*      <OptionTitle> */}
            {/*        2 year */}
            {/*      </OptionTitle> */}

            {/*      <p style={{ textAlign: 'center' }}> */}
            {/*        <Price> */}
            {/*          $110 */}
            {/*        </Price> */}
            {/*        <PricePeriod> */}
            {/*          /month */}
            {/*        </PricePeriod> */}
            {/*      </p> */}

            {/*      <Billed> */}
            {/*        $2,650 every two years */}
            {/*      </Billed> */}

            {/*      <UList> */}
            {/*        <li><strong>30 listing leads</strong></li> */}
            {/*        <li><span>Setup & Onboarding</span></li> */}
            {/*        <li><span>Full data import</span></li> */}
            {/*        <li><span>Premium support</span></li> */}
            {/*      </UList> */}
            {/*    </div> */}

            {/*    <div> */}
            {/*      <Button */}
            {/*        type="primary" */}
            {/*        className={styles.button} */}
            {/*        onClick={selectSubscription('3')} */}
            {/*      > */}
            {/*        Get Started */}
            {/*      </Button> */}
            {/* </div> */}
            {/* </OptionWrapper> */}
            {/* </Col> */}
          </Row>
        </Save>
      </Col>
    </Row>
  );
};

const { func } = PropTypes;

SubscriptionChoiceSection.propTypes = {
  setDetailsFormFields: func.isRequired,
};

export default SubscriptionChoiceSection;
