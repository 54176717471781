import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
} from 'react-router-dom';

import URLparser from 'url-parse';

import { browserHistory } from 'browserHistory';
import routes from 'constants/routes';

import StripeProvider from 'components/StripeProvider';

import CustomOffer from 'pages/CustomOffer';
import SignupV3 from 'pages/SignupV3';
// import YourDetails from 'pages/YourDetails';
// import CompanyDetails from 'pages/CompanyDetails';
// import Payment from 'pages/Payment';
// import Confirmation from 'pages/Confirmation';
import ErrorOnSignup from 'pages/ErrorOnSignup';
// import LandingPage3 from 'pages/LandingPage3';

import { setClientSource } from 'store/actions/clientSource';
import { setLandingVersion } from 'store/actions/landingVersion';
import {
  setDetailsFormFields,
  getCustomerSecret,
} from 'store/actions/detailsForm';

import setSessionToken from 'utils/setSessionToken';

import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module';

import ThemeProvider from 'ui/ThemeProvider';

ReactGA.initialize(process.env.REACT_APP_TRACKING_ID);
ReactGA.pageview(window.location.pathname + window.location.search);

TagManager.initialize({ gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID });

const marketingQueries = {
  li: 'linkedin',
  eml: 'email',
  fb: 'facebook',
  ggl: 'google',
  nw: 'newsWatch',
};

const landingVersions = {
  lp2: 'LP2',
  lp3: 'LP3',
};

const getMarketingQuery = () => {
  const {
    pathname,
  } = URLparser(window.location, true);

  const firstLevelPath = pathname.split('/')[1];
  const secondLevelPath = pathname.split('/')[2];

  if (marketingQueries[firstLevelPath]) {
    return marketingQueries[firstLevelPath];
  }

  if (marketingQueries[secondLevelPath]) {
    return marketingQueries[secondLevelPath];
  }

  return null;
};

const getLandingVersion = () => {
  const {
    pathname,
  } = URLparser(window.location, true);

  const firstLevelPath = pathname.split('/')[1];

  if (landingVersions[firstLevelPath]) {
    return landingVersions[firstLevelPath];
  }

  return null;
};

const getQueryData = () => {
  const {
    query,
  } = URLparser(window.location, true);

  return query;
};

const App = (props) => {
  const {
    setClientSourceState,
    setLandingVersionState,
    setDetailsFormFieldsState,
    // getCustomerSecret,
  } = props;

  setClientSourceState(getMarketingQuery());
  setLandingVersionState(getLandingVersion());

  const {
    email,
    firstName,
    lastName,
    phone,
  } = getQueryData();

  if (email || firstName || lastName || phone) {
    setDetailsFormFieldsState({
      clientEmail: { value: email },
      firstName: { value: firstName },
      lastName: { value: lastName },
      phoneNumber: { value: phone },
    });
  }

  useEffect(() => {
    getCustomerSecret(email);
  }, [
    email,
  ]);

  useEffect(() => {
    setSessionToken();
  }, []);

  // if (getLandingVersion() === 'LP3') {
  //   return (
  //     <Router history={browserHistory}>
  //       <Switch>
  //         <Route exact path={routes.landingPage3} component={LandingPage3} />
  //         <Route exact path={routes.signUp} component={YourDetails} />
  //         <Route exact path={routes.companyDetails} component={CompanyDetails} />
  //         <Route exact path={routes.payment} component={Payment} />
  //         <Route exact path={routes.paymentDeal} component={Payment} />
  //         <Route exact path={routes.confirmation} component={Confirmation} />
  //         <Route exact path={routes.startUsing} component={StartUsing} />
  //         <Redirect to={routes.landingPage3} />
  //       </Switch>
  //     </Router>
  //   );
  // }

  return (
    <ThemeProvider>
      <StripeProvider>
        <Router history={browserHistory}>
          <Switch>
            <Route exact path={routes.signUp} component={SignupV3} />
            <Route path={`${routes.offers}/:token`} component={CustomOffer} />
            {/* <Route exact path={routes.signUp} component={YourDetails} /> */}
            {/* <Route exact path={routes.companyDetails} component={CompanyDetails} /> */}
            {/* <Route exact path={routes.payment} component={Payment} />
            <Route exact path={routes.paymentDeal} component={Payment} /> */}
            {/* <Route exact path={routes.confirmation} component={Confirmation} /> */}
            <Route exact path={routes.errorOnSignup} component={ErrorOnSignup} />
            <Route exact path={routes.creationProgress} component={SignupV3} />
            <Redirect to={routes.signUp} />
          </Switch>
        </Router>
      </StripeProvider>
    </ThemeProvider>
  );
};

const mapDispatchToProps = {
  setClientSourceState: setClientSource,
  setLandingVersionState: setLandingVersion,
  setDetailsFormFieldsState: setDetailsFormFields,
  getCustomerSecret,
};

App.propTypes = {
  setClientSourceState: PropTypes.func.isRequired,
  setLandingVersionState: PropTypes.func.isRequired,
  setDetailsFormFieldsState: PropTypes.func.isRequired,
  getCustomerSecret: PropTypes.func.isRequired,
};

export default connect(null, mapDispatchToProps)(App);
