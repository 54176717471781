import { combineReducers } from 'redux';

// Reducers
import account from './account';
import creditCardForm from './creditCardForm';
import detailsForm from './detailsForm';
import marketing from './marketing';
import user from './user';

const rootReducer = combineReducers({
  account,
  creditCardForm,
  detailsForm,
  marketing,
  user,
});

export default rootReducer;
