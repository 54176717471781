import apiConnector from 'api';

const checkIfAccountExists = async (email) => {
  const endpoint = `/checkIfAccountExists/${email}`;

  const response = await apiConnector.global.get(endpoint);

  return response.data;
};

export default checkIfAccountExists;
